module.exports = {
  "props": [
    {
      "name": "absolute",
      "type": "boolean",
      "default": "false",
      "source": "positionable",
      "description": {
        "en": "Applies **position: absolute** to the component.",
        "ja": "コンポーネントに **position: absolute** を適用します。",
        "zh-Hans": "给组件应用 **position: absolute**"
      }
    },
    {
      "name": "activator",
      "type": "any",
      "default": "undefined",
      "source": "activatable",
      "description": {
        "en": "Designate a custom activator when the `activator` slot is not used. String can be any valid querySelector and Object can be any valid Node.",
        "ja": "`activator` slot が使われていない場合、カスタムの activator を指定します。String には任意の有効な querySelector が、Object には任意の有効な Node が指定できます。",
        "zh-Hans": "当未使用 `activator` 插槽时，指定自定义激活器。String 可以是任何有效的 querySelector，对象可以是任何有效的 Node。"
      }
    },
    {
      "name": "allow-overflow",
      "type": "boolean",
      "default": "false",
      "source": "menuable",
      "description": {
        "en": "Removes overflow re-positioning for the content",
        "ja": "コンテンツのオーバーフローの再配置を削除します",
        "zh-Hans": "移除内容的溢出重新定位"
      }
    },
    {
      "name": "attach",
      "type": "any",
      "default": false,
      "source": "detachable",
      "description": {
        "en": "Specifies which DOM element that this component should detach to. String can be any valid querySelector and Object can be any valid Node. This will attach to the root `v-app` component by default.",
        "ja": "このコンポーネントが切り離すべき DOM 要素を指定します。String には任意の有効な querySelector、Object には任意の有効な Node を指定します。デフォルトではルートの `v-app` コンポーネントにアタッチされます。",
        "zh-Hans": "使组件脱离当前节点并且指向一个新的DOM元素。可以是任何document. querySelector 有效的字符串或者任何有效的节点。将默认指定并附加到根节点`v-app`下。"
      }
    },
    {
      "name": "bottom",
      "type": "boolean",
      "default": "false",
      "source": "positionable",
      "description": {
        "en": "Aligns the component towards the bottom.",
        "ja": "コンポーネントを下部に配置します。",
        "zh-Hans": "将组件向底部对齐。"
      }
    },
    {
      "name": "close-delay",
      "type": [
        "number",
        "string"
      ],
      "default": 0,
      "source": "delayable",
      "description": {
        "en": "Delay (in ms) after which menu closes (when open-on-hover prop is set to true)",
        "ja": "open-on-hover prop が true の場合に、メニューが閉じられるまでの遅延時間。(ミリ秒単位)",
        "zh-Hans": "当（open-on-hover）属性设置为true时，会在菜单关闭之后延时（以毫秒为单位）"
      }
    },
    {
      "name": "color",
      "type": "string",
      "default": "undefined",
      "source": "colorable",
      "description": {
        "en": "Applies specified color to the control - it can be the name of material color (for example `success` or `purple`) or css color (`#033` or `rgba(255, 0, 0, 0.5)`). You can find a list of built-in classes on the [colors page](/styles/colors#material-colors).",
        "ja": "指定された色をコントロールに適用します。利用できるのはマテリアルカラーの名前 (たとえば、`success` または ` purple`) または css 表記の色 (`#033`または` rgba(255, 0, 0, 0.5) `) です。[colors のページ](/styles/colors#material-colors) で、組み込みクラスのリストが確認できます。",
        "zh-Hans": "指定控件颜色，颜色值可以是 Material 颜色（例如 <code>success</code>或者 <code>purple</code> ）也可以是 Css 颜色 （例如 <code>#033</code> 或者 <code>rgba(255, 0, 0, 0.5)</code>）。在 [colors page](/zh-Hans/styles/colors#material-colors)中可以找到内置颜色类列表。"
      }
    },
    {
      "name": "content-class",
      "type": "string",
      "default": "undefined",
      "source": "detachable",
      "description": {
        "en": "Applies a custom class to the detached element. This is useful because the content is moved to the beginning of the `v-app` component (unless the **attach** prop is provided) and is not targetable by classes passed directly on the component.",
        "ja": "デタッチされた要素にカスタムクラスを適用します。コンテンツが `v-app`コンポーネントの先頭に移動され(**attach** propが与えられない限り)、コンポーネントに直接渡されたクラスが対象とならないので便利です。",
        "zh-Hans": "将自定义的class应用于被分离的此元素。由于此元素的内容将被附加到`v-app`组件开头(除非你设置了**attach**属性)，并且class属性不会将css类直接传递给组件，所以这个实行非常有用。"
      }
    },
    {
      "name": "disabled",
      "type": "boolean",
      "default": "false",
      "source": "activatable",
      "description": {
        "en": "Disables the tooltip",
        "ja": "ツールチップを無効にする",
        "zh-Hans": "禁用提示"
      }
    },
    {
      "name": "eager",
      "type": "boolean",
      "default": "false",
      "source": "bootable",
      "description": {
        "en": "Will force the components content to render on mounted. This is useful if you have content that will not be rendered in the DOM that you want crawled for SEO.",
        "ja": "コンポーネントのコンテンツを mounted 時に render することを強制します。この機能は、SEO のために crawl させたい DOM の中に render されないコンテンツを持っているときに役に立ちます。",
        "zh-Hans": "将强制组件内容在加载时呈现。如果存在内容的话，则不会在 DOM 中渲染，如果你想优化 SEO，这是非常有用的。"
      }
    },
    {
      "name": "internal-activator",
      "type": "boolean",
      "default": "false",
      "source": "activatable",
      "description": {
        "en": "Designates whether to use an internal activator",
        "ja": "内部アクティベーターを使用するか指定します",
        "zh-Hans": "指定是否使用内部激活器"
      }
    },
    {
      "name": "left",
      "type": "boolean",
      "default": "false",
      "source": "positionable",
      "description": {
        "en": "Aligns the component towards the left.",
        "ja": "コンポーネントを左に配置します。",
        "zh-Hans": "将组件向左边对齐。"
      }
    },
    {
      "name": "max-width",
      "type": [
        "number",
        "string"
      ],
      "default": "auto",
      "source": "menuable",
      "description": {
        "en": "Sets the maximum width for the content",
        "ja": "コンテンツの横幅の最大値を設定します。",
        "zh-Hans": "设置内容的最大宽度"
      }
    },
    {
      "name": "min-width",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "menuable",
      "description": {
        "en": "Sets the minimum width for the content",
        "ja": "コンテンツの横幅の最小値を設定します。",
        "zh-Hans": "设置内容的最小宽度"
      }
    },
    {
      "name": "nudge-bottom",
      "type": [
        "number",
        "string"
      ],
      "default": 0,
      "source": "menuable",
      "description": {
        "en": "Nudge the content to the bottom",
        "ja": "コンテンツをbottomに移動します",
        "zh-Hans": "向底部微调内容"
      }
    },
    {
      "name": "nudge-left",
      "type": [
        "number",
        "string"
      ],
      "default": 0,
      "source": "menuable",
      "description": {
        "en": "Nudge the content to the left",
        "ja": "コンテンツをleftに移動します",
        "zh-Hans": "向左侧微调内容"
      }
    },
    {
      "name": "nudge-right",
      "type": [
        "number",
        "string"
      ],
      "default": 0,
      "source": "menuable",
      "description": {
        "en": "Nudge the content to the right",
        "ja": "コンテンツをrightに移動します",
        "zh-Hans": "向右侧微调内容"
      }
    },
    {
      "name": "nudge-top",
      "type": [
        "number",
        "string"
      ],
      "default": 0,
      "source": "menuable",
      "description": {
        "en": "Nudge the content to the top",
        "ja": "コンテンツをtopに移動します",
        "zh-Hans": "向顶部微调内容"
      }
    },
    {
      "name": "nudge-width",
      "type": [
        "number",
        "string"
      ],
      "default": 0,
      "source": "menuable",
      "description": {
        "en": "Nudge the content width",
        "ja": "コンテンツの幅を微調整します",
        "zh-Hans": "微调内容宽度"
      }
    },
    {
      "name": "offset-overflow",
      "type": "boolean",
      "default": "false",
      "source": "menuable",
      "description": {
        "en": "Causes the component to flip to the opposite side when repositioned due to overflow",
        "ja": "オーバーフローによる再配置の際、コンポーネントが反対側に反転します",
        "zh-Hans": "当由于溢出而重新定位时，导致组件翻转到另一侧"
      }
    },
    {
      "name": "open-delay",
      "type": [
        "number",
        "string"
      ],
      "default": 0,
      "source": "delayable",
      "description": {
        "en": "Delay (in ms) after which tooltip opens (when `open-on-hover` prop is set to **true**)",
        "ja": "Open-on-hover prop が true の場合に、メニューが開くまでの遅延時間。(ミリ秒単位**)",
        "zh-Hans": "打开工具提示后的延迟时间（以毫秒为单位）（`open-on-hover` 属性设置为 **true** 时）"
      }
    },
    {
      "name": "open-on-click",
      "type": "boolean",
      "default": "true",
      "source": "activatable",
      "description": {
        "en": "Designates whether the tooltip should open on activator click",
        "ja": "アクティベーターのクリック時にツールチップを開くかどうかを指定します",
        "zh-Hans": "指定是否应在激活器单击时打开工具提示"
      }
    },
    {
      "name": "open-on-focus",
      "type": "boolean",
      "default": "true",
      "source": "activatable",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "open-on-hover",
      "type": "boolean",
      "default": "true",
      "source": "activatable",
      "description": {
        "en": "Designates whether the tooltip should open on activator hover",
        "ja": "アクティベーターのクリック時にツールチップを開くかどうかを指定します",
        "zh-Hans": "指定是否在激活器悬停时打开工具提示"
      }
    },
    {
      "name": "position-x",
      "type": "number",
      "default": "undefined",
      "source": "menuable",
      "description": {
        "en": "Used to position the content when not using an activator slot",
        "ja": "アクティベータースロットを使用しない場合にコンテンツを配置するために使用します",
        "zh-Hans": "用于在不使用激活器插槽时定位内容"
      }
    },
    {
      "name": "position-y",
      "type": "number",
      "default": "undefined",
      "source": "menuable",
      "description": {
        "en": "Used to position the content when not using an activator slot",
        "ja": "アクティベータースロットを使用しない場合にコンテンツを配置するために使用します",
        "zh-Hans": "用于在不使用激活器插槽时定位内容"
      }
    },
    {
      "name": "right",
      "type": "boolean",
      "default": "false",
      "source": "positionable",
      "description": {
        "en": "Aligns the component towards the right.",
        "ja": "コンポーネントを右に配置します。",
        "zh-Hans": "将组件向右边对齐。"
      }
    },
    {
      "name": "tag",
      "type": "string",
      "default": "'span'",
      "source": "v-tooltip",
      "description": {
        "en": "Specifies a custom tag for the activator wrapper",
        "ja": "activator のラッパーにカスタムタグを指定します",
        "zh-Hans": "指定激活程序包装的自定义标签"
      }
    },
    {
      "name": "top",
      "type": "boolean",
      "default": "false",
      "source": "positionable",
      "description": {
        "en": "Aligns the content towards the top.",
        "ja": "コンポーネントを上部に配置します。",
        "zh-Hans": "将组件向顶部对齐。"
      }
    },
    {
      "name": "transition",
      "type": "string",
      "default": "undefined",
      "source": "v-tooltip",
      "description": {
        "en": "Sets the component transition. Can be one of the [built in transitions](/styles/transitions) or one your own.",
        "ja": "コンポーネントのトランジションを設定します。 [組み込みトランジション](/styles/transitions) のいずれか、または独自のものを使用できます。",
        "zh-Hans": "设置组件转换。可以是一个 [built in transitions](/styles/transitions) 或者是自己自定义的。"
      }
    },
    {
      "name": "value",
      "type": "any",
      "default": "undefined",
      "source": "toggleable",
      "description": {
        "en": "Controls whether the component is visible or hidden.",
        "ja": "コンポーネントを表示するか非表示にするかを制御します。",
        "zh-Hans": "控制组件可见还是隐藏。"
      }
    },
    {
      "name": "z-index",
      "type": [
        "number",
        "string"
      ],
      "default": "undefined",
      "source": "menuable",
      "description": {
        "en": "The z-index used for the component",
        "ja": "コンポーネントに使用されるz-index",
        "zh-Hans": "用于组件的 z-index"
      }
    }
  ],
  "mixins": [
    "activatable",
    "bootable",
    "colorable",
    "delayable",
    "delayable",
    "dependent",
    "detachable",
    "menuable",
    "positionable",
    "stackable",
    "toggleable"
  ],
  "slots": [
    {
      "name": "activator",
      "props": {
        "attrs": "{ role: string, aria-haspopup: boolean, aria-expanded: string }",
        "on": "{ [eventName]: eventHandler }",
        "value": "boolean"
      },
      "description": {
        "en": "When used, will activate the component when clicked (or hover for specific components). This manually stops the event propagation. Without this slot, if you open the component through its model, you will need to manually stop the event propagation",
        "ja": "使用すると、クリック時 (または特定のコンポーネントではホバー時) にコンポーネントを有効にします。これにより手動でイベントの propagation を stop されます。この slot がない場合、モデル経由でコンポーネントを開いたときには、自分でイベントの propagation を stop する必要があります。",
        "zh-Hans": "使用时，将在点击后激活组件（或悬停特定组件），它手动阻止事件传播。如果通过模型打开组件而没有此插槽，则需要手动阻止事件传播。"
      }
    },
    {
      "name": "default",
      "description": {
        "en": "The default Vue slot.",
        "ja": "デフォルトの Vue slot",
        "zh-Hans": "默认Vue 插槽。"
      }
    }
  ],
  "events": [],
  "functions": [],
  "name": "v-tooltip",
  "sass": [
    {
      "name": "$tooltip-background-color",
      "default": "rgba(map-get($grey, 'darken-2'), 0.9) !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$tooltip-text-color",
      "default": "map-get($shades, 'white') !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$tooltip-opacity",
      "default": ".9 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$tooltip-border-radius",
      "default": "$border-radius-root !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$tooltip-font-size",
      "default": "14px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$tooltip-transition-timing-function",
      "default": "map-get($transition, 'linear-out-slow-in') !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$tooltip-transition-enter-duration",
      "default": "150ms !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$tooltip-transition-leave-duration",
      "default": "75ms !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$tooltip-padding",
      "default": "5px 16px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    }
  ],
  "component": true
}