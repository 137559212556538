module.exports = {
  "props": [
    {
      "name": "categories",
      "type": [
        "array",
        "string"
      ],
      "default": "undefined",
      "source": "calendar-with-events",
      "description": {
        "en": "Specifies what categories to display in the `category` view. This controls the order of the categories as well. If the calendar uses events any categories specified in those events not specified in this value are dynamically rendered in the view unless `category-hide-dynamic` is true.",
        "ja": "「カテゴリ」ビューに表示するカテゴリとその順序を指定します。 カレンダーがイベントを使用する場合、この値で指定されていないイベントで指定されたカテゴリは、<code>category-hide-dynamic</code> が<code>true</code>でない限り、ビューで動的にレンダリングされます。",
        "zh-Hans": "指定要在`类别`视图中显示的类别. 这也控制类别的排序. 如果日历使用事件，则除非“category-hide-dynamic”为true，否则在此值中未指定的事件中指定的任何类别都将在视图中动态呈现"
      }
    },
    {
      "name": "category-days",
      "type": [
        "number",
        "string"
      ],
      "default": 1,
      "source": "calendar-with-events",
      "description": {
        "en": "The number of days to render in the `category` view.",
        "ja": "`category` ビューに表示する日数です。",
        "zh-Hans": "在`类别`视图中显示的天数"
      }
    },
    {
      "name": "category-for-invalid",
      "type": "string",
      "default": "undefined",
      "source": "calendar-with-events",
      "description": {
        "en": "The category to place events in that have invalid categories. A category is invalid when it is not a string. By default events without a category are not displayed until this value is specified.",
        "ja": "無効なカテゴリを持つイベントを配置するカテゴリ。文字列でない場合、カテゴリは無効です。 デフォルトでは、この値が指定されるまで、カテゴリのないイベントは表示されません。",
        "zh-Hans": "用于**放置具有无效类别的事件**的类别. 类别必须是字符串. 默认情况下, 在没有指定类别之前事件不会被显示."
      }
    },
    {
      "name": "category-hide-dynamic",
      "type": "boolean",
      "default": "false",
      "source": "calendar-with-events",
      "description": {
        "en": "Sets whether categories specified in an event should be hidden if it's not defined in `categories`.",
        "ja": "イベントで指定されたカテゴリが `categories` で定義されていない場合に非表示にするかどうかを設定します。",
        "zh-Hans": "设置事件中指定的类别如果没有在`类别`中定义时是否隐藏."
      }
    },
    {
      "name": "category-show-all",
      "type": "boolean",
      "default": "false",
      "source": "calendar-with-events",
      "description": {
        "en": "Set whether the `category` view should show all defined `categories` even if there are no events for a category.",
        "ja": "カテゴリにイベントがない場合でも、`category` ビューに定義されたすべてのカテゴリを表示するかどうかを設定します。",
        "zh-Hans": "设置`类别`视图是否显示所有定义的类别, 包含没有事件的类别."
      }
    },
    {
      "name": "category-text",
      "type": [
        "string",
        "function"
      ],
      "default": "undefined",
      "source": "calendar-with-events",
      "description": {
        "en": "If categories is a list of objects, you can use this to determine what property to print out as the category text on the calendar. You can provide a function to do some logic or just define the prop name. It's similar to item-text on v-select",
        "ja": "categories がオブジェクトのリストである場合、どのプロパティをカレンダーのカテゴリ テキストとして表示するかを決定できます。 いくつかのロジックを実行する関数を提供したり、プロパティ名を定義することができます。v-select の item-text に似ています。",
        "zh-Hans": "如果 category 是一个对象列表，你可以用它来决定打印出什么属性作为日历组件上的类别文本。你可以提供一个函数来执行一些逻辑，或者直接定义属性名称。它类似于 v-select 的 item-text。"
      }
    },
    {
      "name": "color",
      "type": "string",
      "default": "undefined",
      "source": "colorable",
      "description": {
        "en": "Applies specified color to the control - it can be the name of material color (for example `success` or `purple`) or css color (`#033` or `rgba(255, 0, 0, 0.5)`). You can find a list of built-in classes on the [colors page](/styles/colors#material-colors).",
        "ja": "指定された色をコントロールに適用します。利用できるのはマテリアルカラーの名前 (たとえば、`success` または ` purple`) または css 表記の色 (`#033`または` rgba(255, 0, 0, 0.5) `) です。[colors のページ](/styles/colors#material-colors) で、組み込みクラスのリストが確認できます。",
        "zh-Hans": "指定控件颜色，颜色值可以是 Material 颜色（例如 <code>success</code>或者 <code>purple</code> ）也可以是 Css 颜色 （例如 <code>#033</code> 或者 <code>rgba(255, 0, 0, 0.5)</code>）。在 [colors page](/zh-Hans/styles/colors#material-colors)中可以找到内置颜色类列表。"
      }
    },
    {
      "name": "dark",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the dark theme variant to the component. You can find more information on the Material Design documentation for [dark themes](https://material.io/design/color/dark-theme.html).",
        "ja": "コンポーネントにダークテーマを適応します。詳しく知りたい場合は、Material Design documentationの<a href=\"https://material.io/design/color/dark-theme.html\">dark themes</a>を参照してください。",
        "zh-Hans": "将暗色主题变量应用到组件。你可以在 [dark themes](https://material.io/design/color/dark-theme.html) 的 Material Design 文档中找到更多有关信息。"
      }
    },
    {
      "name": "day-format",
      "type": "function",
      "default": "null",
      "source": "calendar-with-events",
      "description": {
        "en": "Formats day of the month string that appears in a day to a specified locale",
        "ja": "日付の月の表記を指定されたロケールでフォーマットします。",
        "zh-Hans": "将某日中出现的月字符串的日期格式化为指定区域设置"
      }
    },
    {
      "name": "end",
      "type": [
        "string",
        "number",
        "date"
      ],
      "default": "undefined",
      "source": "calendar-with-events",
      "description": {
        "en": "The ending date on the calendar (inclusive) in the format of `YYYY-MM-DD`. This may be ignored depending on the `type` of the calendar.",
        "ja": "`YYYY-MM-DD` の形式を含むカレンダーの終了日を指定します。これはカレンダーの `type` に依存して無視されることがあります。",
        "zh-Hans": "日历的结束日期（包括），格式为 `YYYY-MM-DD。根据日历的 `type`，这可能会被忽略。"
      }
    },
    {
      "name": "event-category",
      "type": [
        "string",
        "function"
      ],
      "default": "category",
      "source": "calendar-with-events",
      "description": {
        "en": "Set property of *event*'s category. Instead of a property a function can be given which takes an event and returns the category.",
        "ja": "*event* のカテゴリのプロパティを設定します。プロパティの代わりに、イベントを受け取ってカテゴリを返す関数を与えることができます。",
        "zh-Hans": "设置*事件*的类别属性, 可以指定一个接受事件并返回类别的函数来代替该属性."
      }
    },
    {
      "name": "event-color",
      "type": [
        "string",
        "function"
      ],
      "default": "primary",
      "source": "calendar-with-events",
      "description": {
        "en": "A background color for all events or a function which accepts an event object passed to the calendar to return a color.",
        "ja": "すべてのイベントの背景色、もしくはカレンダーに渡されたイベントオブジェクトを受け取って色を返す関数。",
        "zh-Hans": "所有事件的背景色或接受传递给日历的事件对象以返回颜色的函数。"
      }
    },
    {
      "name": "event-end",
      "type": "string",
      "default": "'end'",
      "source": "calendar-with-events",
      "description": {
        "en": "Set property of *event*'s end timestamp.",
        "ja": "**イベント**の終了時刻のプロパティを設定します。",
        "zh-Hans": "设置 *event* 的结束时间戳的属性。"
      }
    },
    {
      "name": "event-height",
      "type": "number",
      "default": 20,
      "source": "calendar-with-events",
      "description": {
        "en": "The height of an event in pixels in the `month` view and at the top of the `day` views.",
        "ja": "`month` ビューと `day` ビューの最上部にあるピクセル単位のイベントの高さ。",
        "zh-Hans": "事件的高度（以像素为单位）在 `month` 视图和 `day` 视图的顶部。"
      }
    },
    {
      "name": "event-margin-bottom",
      "type": "number",
      "default": 1,
      "source": "calendar-with-events",
      "description": {
        "en": "Margin bottom for event",
        "ja": "イベント時のボトムのマージン",
        "zh-Hans": "事件底部边距"
      }
    },
    {
      "name": "event-more",
      "type": "boolean",
      "default": "true",
      "source": "calendar-with-events",
      "description": {
        "en": "Whether the more 'button' is displayed on a calendar with too many events in a given day. It will say something like '5 more' and when clicked generates a `click:more` event.",
        "ja": "特定のイベントが多すぎるカレンダーのdayで追加'button'が表示されるかどうか。 'その他5件' のように表示され、クリックされると `click:more` イベントを生成します。",
        "zh-Hans": "在给定日期内事件太多的日历上是否显示更多 'button'。它将显示类似于 '5 more' 的内容，当单击时会生成一个 `click:more` 事件。"
      }
    },
    {
      "name": "event-more-text",
      "type": "string",
      "default": "'$vuetify.calendar.moreEvents'",
      "source": "calendar-with-events",
      "description": {
        "en": "The text to display in the more 'button' given the number of hidden events.",
        "ja": "非表示のイベントの数を指定して、詳細 'button'に表示するテキスト。",
        "zh-Hans": "在给定隐藏事件数量的情况下，在更多 'button' 中显示的文本。"
      }
    },
    {
      "name": "event-name",
      "type": [
        "string",
        "function"
      ],
      "default": "name",
      "source": "calendar-with-events",
      "description": {
        "en": "Set property of *event*'s displayed name, or a function which accepts an event object passed to the calendar as the first argument and a flag signalling whether the name is for a timed event (true) or an event over a day.",
        "ja": "*event*の表示名のプロパティを設定するか、カレンダーに渡されたイベントオブジェクトを第一引数として受け取り、その名前が時限付きイベント(true) なのか、一日以上のイベントなのかを示すフラグを指定する関数を設定します。",
        "zh-Hans": "设置 *event* 的显示名称的属性，或接受作为第一个参数传递给日历的事件对象的函数，并设置一个标志，指示该名称是用于一个定时事件 (true) 或是一个超过一天的事件。"
      }
    },
    {
      "name": "event-overlap-mode",
      "type": [
        "string",
        "function"
      ],
      "default": "stack",
      "source": "calendar-with-events",
      "description": {
        "en": "One of `stack`, `column`, or a custom render function",
        "ja": "One of `stack`, `column`, or a custom render function",
        "zh-Hans": "`stack`, `column` 或自定义渲染函数之一"
      }
    },
    {
      "name": "event-overlap-threshold",
      "type": [
        "string",
        "number"
      ],
      "default": 60,
      "source": "calendar-with-events",
      "description": {
        "en": "A value in minutes that's used to determine whether two timed events should be placed in column beside each other or should be treated as slightly overlapping events.",
        "ja": "A value in minutes that's used to determine whether two timed events should be placed in column beside each other or should be treated as slightly overlapping events.",
        "zh-Hans": "以分钟为单位的值，用于确定两个定时事件应在彼此相邻的列中放置还是应视为稍微重叠的事件。"
      }
    },
    {
      "name": "event-ripple",
      "type": [
        "boolean",
        "object"
      ],
      "default": "undefined",
      "source": "calendar-with-events",
      "description": {
        "en": "Applies the `v-ripple` directive.",
        "ja": "`v-ripple` ディレクティブを適用します。",
        "zh-Hans": "应用 `v-ripple` 指令。"
      }
    },
    {
      "name": "event-start",
      "type": "string",
      "default": "'start'",
      "source": "calendar-with-events",
      "description": {
        "en": "Set property of *event*'s start timestamp.",
        "ja": "**イベント**の開始時刻のプロパティを設定します。",
        "zh-Hans": "设置 *event* 的启动时间戳的属性。"
      }
    },
    {
      "name": "event-text-color",
      "type": [
        "string",
        "function"
      ],
      "default": "white",
      "source": "calendar-with-events",
      "description": {
        "en": "A text color for all events or a function which accepts an event object passed to the calendar to return a color.",
        "ja": "すべてのイベントでのテキスト色、もしくはカレンダーに渡されたイベントオブジェクトを受け取って色を返す関数。",
        "zh-Hans": "所有事件的文本颜色，或接受传递给日历的事件对象以返回颜色的函数。"
      }
    },
    {
      "name": "event-timed",
      "type": [
        "string",
        "function"
      ],
      "default": "timed",
      "source": "calendar-with-events",
      "description": {
        "en": "If Dates or milliseconds are used as the start or end timestamp of an event, this prop can be a string to a property on the event that is truthy if the event is a timed event or a function which takes the event and returns a truthy value if the event is a timed event.",
        "ja": "If Dates or milliseconds are used as the start or end timestamp of an event, this prop can be a string to a property on the event that is truthy if the event is a timed event or a function which takes the event and returns a truthy value if the event is a timed event.",
        "zh-Hans": "如果使用日期或毫秒作为事件开始或结束的时间戳, 此prop可以是事件属性的字符串并且当事件是定时事件时返回真值, 或者也可以是一个接受事件的函数并且在事件是定时事件时返回一个真值."
      }
    },
    {
      "name": "events",
      "type": "array",
      "default": [],
      "source": "calendar-with-events",
      "description": {
        "en": "An array of event objects with a property for a start timestamp and optionally a name and end timestamp. If an end timestamp is not given, the value of start will be used. If no name is given, you must provide an implementation for the `event` slot.",
        "ja": "開始時刻のタイムスタンプと、終了時刻のタイムスタンプ（オプション）の配列を入れます。終了時刻のタイムスタンプが指定されていない場合は、開始時刻のタイムスタンプの値が使われます。名前が指定されていない場合は、`event`スロットを実装する必要があります。",
        "zh-Hans": "事件对象数组，具有开始时间戳（可选）和名称及结束时间戳的属性。 如果未提供结束时间戳，则将使用开始值。 如果没有给出名称，则必须为 `event` \n 插槽提供一个实现。"
      }
    },
    {
      "name": "first-interval",
      "type": [
        "number",
        "string"
      ],
      "default": 0,
      "source": "v-calendar",
      "description": {
        "en": "The first interval to display in the `day` view. If `intervalMinutes` is set to 60 and this is set to 9 the first time in the view is 9am.",
        "ja": "`day`ビューに表示する最初の間隔。 `intervalMinutes`が60に設定されていて、これが9に設定されている場合、ビューの最初の時間は午前9時になります。",
        "zh-Hans": "在 `day` 视图中显示的第一个间隔。如果 `intervalMinutes` 设置为 60，而这个设置为 9，则视图中的第一次是上午9点。"
      }
    },
    {
      "name": "first-time",
      "type": [
        "number",
        "string",
        "object"
      ],
      "default": "undefined",
      "source": "v-calendar",
      "description": {
        "en": "The first time to display in the `day` view. If specified, this overwrites any `firstInterval` value specified. This can be the number of minutes since midnight, a string in the format of `HH:mm`, or an object with number properties hour and minute.",
        "ja": "`day`ビューで最初に表示する時間。指定された場合、指定値で上書きされます。 これは真夜中から分数、`H:mm`の形式での文字列、または時と分数のプロパティを持つオブジェクトです。\nday` ビューに最初に表示する時間。指定された場合は、`firstInterval` の値を上書きします。これは深夜0時からの分minutes、`HH:mm` 形式の文字列、あるいは時と分のプロパティを持つオブジェクトを指定することができます。",
        "zh-Hans": "在`天`视图中显示的第一个(开始)时间。如果指定，则覆盖指定的任何“firstInterval”值。这可以是从午夜开始的分钟数，格式为“HH:mm”的字符串，或具有数字属性hour和minute的对象。"
      }
    },
    {
      "name": "hide-header",
      "type": "boolean",
      "default": "false",
      "source": "calendar-with-events",
      "description": {
        "en": "If the header at the top of the `day` view should be visible.",
        "ja": "If the header at the top of the `day` view should be visible.",
        "zh-Hans": "`day` 视图顶部的头部是否应该可见。"
      }
    },
    {
      "name": "interval-count",
      "type": [
        "number",
        "string"
      ],
      "default": 24,
      "source": "v-calendar",
      "description": {
        "en": "The number of intervals to display in the `day` view.",
        "ja": "`day`ビューに表示する期間の数。",
        "zh-Hans": "在 `day` 视图中显示的间隔数。"
      }
    },
    {
      "name": "interval-format",
      "type": "function",
      "default": "null",
      "source": "v-calendar",
      "description": {
        "en": "Formats time of day string that appears in the interval gutter of the `day` and `week` view to specified locale",
        "ja": "Formats time of day string that appears in the interval gutter of the `day` and `week` view to specified locale",
        "zh-Hans": "将出现在 `day` 和 `week` 视图的间隔栏中的一天中的时间字符串格式化为指定的区域设置"
      }
    },
    {
      "name": "interval-height",
      "type": [
        "number",
        "string"
      ],
      "default": 48,
      "source": "v-calendar",
      "description": {
        "en": "The height of an interval in pixels in the `day` view.",
        "ja": "The height of an interval in pixels in the `day` view.",
        "zh-Hans": "`day` 视图中间隔的高度（以像素为单位）。"
      }
    },
    {
      "name": "interval-minutes",
      "type": [
        "number",
        "string"
      ],
      "default": 60,
      "source": "v-calendar",
      "description": {
        "en": "The number of minutes the intervals are in the `day` view. A common interval is 60 minutes so the intervals are an hour.",
        "ja": "The number of minutes the intervals are in the `day` view. A common interval is 60 minutes so the intervals are an hour.",
        "zh-Hans": "间隔在 `day` 视图中的分钟数。普通间隔为 60 分钟，因此间隔为 1 小时。"
      }
    },
    {
      "name": "interval-style",
      "type": "function",
      "default": "null",
      "source": "v-calendar",
      "description": {
        "en": "Returns CSS styling to apply to the interval.",
        "ja": "間隔に適用する CSS スタイルを返します。",
        "zh-Hans": "返回要应用于间隔的 CSS 样式。"
      }
    },
    {
      "name": "interval-width",
      "type": [
        "number",
        "string"
      ],
      "default": 60,
      "source": "v-calendar",
      "description": {
        "en": "The width of the interval gutter on the left side in the `day` view.",
        "ja": "`day`ビューの左側にある間隔の幅。",
        "zh-Hans": "`day` 视图中左侧的间隔宽度。"
      }
    },
    {
      "name": "light",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the light theme variant to the component.",
        "ja": "コンポーネントにlightテーマを適用します。",
        "zh-Hans": "为组件设置浅色主题。"
      }
    },
    {
      "name": "locale",
      "type": "string",
      "default": "undefined",
      "source": "localable",
      "description": {
        "en": "The locale of the calendar.",
        "ja": "カレンダーのロケール",
        "zh-Hans": "日历的区域设置。"
      }
    },
    {
      "name": "locale-first-day-of-year",
      "type": [
        "string",
        "number"
      ],
      "default": 0,
      "source": "v-calendar",
      "description": {
        "en": "Sets the day that determines the first week of the year, starting with 0 for **Sunday**. For ISO 8601 this should be 4.",
        "ja": "年の最初の週を決定する日を設定します。**日曜日** の 0 から始まります。ISO 8601 の場合は、4 にする必要があります。",
        "zh-Hans": "设置决定一年中第一周的日期，从 0 开始，**星期日**。对于 ISO 8601，应该是 4。"
      }
    },
    {
      "name": "max-days",
      "type": "number",
      "default": 7,
      "source": "v-calendar",
      "description": {
        "en": "The maximum number of days to display in the custom calendar if an `end` day is not set.",
        "ja": "`end` 日が設定されていない場合にカスタムカレンダーに表示する最大日数。",
        "zh-Hans": "如果未设置 `end` 天，则在自定义日历中显示最大天数。"
      }
    },
    {
      "name": "min-weeks",
      "type": "any",
      "default": 1,
      "source": "v-calendar",
      "description": {
        "en": "The minimum number of weeks to display in the `month` or `week` view.",
        "ja": "`month`または`week`ビューに表示する最小週数。",
        "zh-Hans": "在 `month` 或 `week` 视图中显示的最小周数。"
      }
    },
    {
      "name": "month-format",
      "type": "function",
      "default": "null",
      "source": "v-calendar",
      "description": {
        "en": "Formats month string that appears in a day to specified locale",
        "ja": "日に表示される月の文字列を指定されたロケールでフォーマットします",
        "zh-Hans": "将日期中出现的月份字符串格式化为指定的区域设置"
      }
    },
    {
      "name": "now",
      "type": "string",
      "default": "undefined",
      "source": "times",
      "description": {
        "en": "Override the day & time which is considered now. This is in the format of `YYYY-MM-DD hh:mm:ss`. The calendar is styled according to now.",
        "ja": "Override the day & time which is considered now. This is in the format of `YYYY-MM-DD hh:mm:ss`. The calendar is styled according to now.",
        "zh-Hans": "覆盖现在考虑的日期和时间。格式为 `YYYY-MM-DD hh:mm:ss`。日历是根据现在的情况设计的。"
      }
    },
    {
      "name": "short-intervals",
      "type": "boolean",
      "default": "true",
      "source": "v-calendar",
      "description": {
        "en": "If true, the intervals in the `day` view will be 9 AM as opposed to 09:00 AM",
        "ja": "true の場合、`day` ビューの間隔は 9 AM ではなく 09:00 AM になります。",
        "zh-Hans": "如果为 true，则 `day` 视图中的间隔将为上午 9：00，而不是上午 09：00"
      }
    },
    {
      "name": "short-months",
      "type": "boolean",
      "default": "true",
      "source": "v-calendar",
      "description": {
        "en": "Whether the short versions of a month should be used (Jan vs January).",
        "ja": "月名をショートバージョンにするかどうか (Jan vs January)",
        "zh-Hans": "是否应使用一个月的简短版本 (Jan vs January)。"
      }
    },
    {
      "name": "short-weekdays",
      "type": "boolean",
      "default": "true",
      "source": "calendar-with-events",
      "description": {
        "en": "Whether the short versions of a weekday should be used (Mon vs Monday).",
        "ja": "曜日名をショートバージョンにする (Mon vs Monday)。",
        "zh-Hans": "是否应使用工作日的简短版本 (Mon vs Monday)。"
      }
    },
    {
      "name": "show-interval-label",
      "type": "function",
      "default": "null",
      "source": "v-calendar",
      "description": {
        "en": "Checks if a given day and time should be displayed in the interval gutter of the `day` view.",
        "ja": "Checks if a given day and time should be displayed in the interval gutter of the `day` view.",
        "zh-Hans": "检查给定的日期和时间是否应显示在 `day` 视图的间隔栏中。"
      }
    },
    {
      "name": "show-month-on-first",
      "type": "boolean",
      "default": "true",
      "source": "v-calendar",
      "description": {
        "en": "Whether the name of the month should be displayed on the first day of the month.",
        "ja": "月名を最初の日に表示する",
        "zh-Hans": "是否应在该月的第一天显示该月的名称。"
      }
    },
    {
      "name": "show-week",
      "type": "boolean",
      "default": "false",
      "source": "v-calendar",
      "description": {
        "en": "Whether week numbers should be displayed when using the `month` view.",
        "ja": "`month` ビューを使用するときに週番号を表示する",
        "zh-Hans": "使用“月”视图时是否应该显示周数。"
      }
    },
    {
      "name": "start",
      "type": [
        "string",
        "number",
        "date"
      ],
      "default": "2024-10-07",
      "source": "calendar-with-events",
      "description": {
        "en": "The starting date on the calendar (inclusive) in the format of `YYYY-MM-DD`. This may be ignored depending on the `type` of the calendar.",
        "ja": "カレンダーの開始日 (YYYY-MM-DDを含む) を指定します。\nカレンダーの`type`の値に応じて無視することができます。",
        "zh-Hans": "日历上的开始日期（包括），格式为 `YYYY-MM-DD`。 根据日历的 `type`，可以将其忽略。"
      }
    },
    {
      "name": "type",
      "type": "string",
      "default": "'month'",
      "source": "calendar-with-events",
      "description": {
        "en": "A string which is one of `month`, `week`, `day`, `4day`, `custom-weekly`, `custom-daily`, and `category`. The custom types look at the `start` and `end` dates passed to the component as opposed to the `value`.",
        "ja": "`month`、`week`、`day`、`4day`、`custom-weekly`、`custom-daily`、`category`のいずれかの文字列。 カスタムタイプは、`value`ではなく、コンポーネントに渡される`start`と`end`日付を参照します。",
        "zh-Hans": "一个“month”、“week”、“day”、“4day”、“custom-weekly”、“custom-daily”和“category”的之一的字符串。自定义类型查看传递给组件的“start”和“end”日期，而不是“value”。"
      }
    },
    {
      "name": "value",
      "type": [
        "string",
        "number",
        "date"
      ],
      "default": "undefined",
      "source": "calendar-with-events",
      "description": {
        "en": "A date in the format of `YYYY-MM-DD` which determines what span of time for the calendar.",
        "ja": "カレンダーの期間を指定する `YYYY-MM-DD` 形式の日付。",
        "zh-Hans": "`YYYY-MM-DD` 格式的日期，用于确定日历的时间跨度。"
      }
    },
    {
      "name": "weekday-format",
      "type": "function",
      "default": "null",
      "source": "calendar-with-events",
      "description": {
        "en": "Formats day of the week string that appears in the header to specified locale",
        "ja": "ヘッダーに表示される曜日文字列を指定されたロケールにフォーマットします",
        "zh-Hans": "将标题中显示的星期几字符串格式设置为指定的区域设置"
      }
    },
    {
      "name": "weekdays",
      "type": [
        "array",
        "string"
      ],
      "default": [
        0,
        1,
        2,
        3,
        4,
        5,
        6
      ],
      "source": "calendar-with-events",
      "description": {
        "en": "Specifies which days of the week to display. To display Monday through Friday only, a value of `[1, 2, 3, 4, 5]` can be used. To display a week starting on Monday a value of `[1, 2, 3, 4, 5, 6, 0]` can be used.",
        "ja": "週のどの日を表示するかを指定します。月曜日から金曜日までのみ表示するには、値を `[1, 2, 3, 4, 5]` で指定します。 月曜日はじまりで1週間を表示するには、値を`[1, 2, 3, 4, 5, 6, 0]` と指定します。",
        "zh-Hans": "指定一周中要显示的日期。如果只显示周一到周五，可以使用 `[1, 2, 3, 4, 5]` \n 的值。要显示周一开始的一周，可以使用值 `[1, 2, 3, 4, 5, 6, 0]`。"
      }
    }
  ],
  "mixins": [
    "colorable",
    "localable",
    "mouse",
    "themeable",
    "times"
  ],
  "slots": [
    {
      "name": "category",
      "props": {
        "date": "string",
        "time": "string",
        "year": "number",
        "month": "number",
        "day": "number",
        "hour": "number",
        "minute": "number",
        "weekday": "number",
        "hasDay": "boolean",
        "hasTime": "boolean",
        "past": "boolean",
        "present": "boolean",
        "future": "boolean",
        "week": [
          {
            "date": "string",
            "time": "string",
            "year": "number",
            "month": "number",
            "day": "number",
            "hour": "number",
            "minute": "number",
            "weekday": "number",
            "hasDay": "boolean",
            "hasTime": "boolean",
            "past": "boolean",
            "present": "boolean",
            "future": "boolean"
          }
        ],
        "category": "string | null"
      },
      "description": {
        "en": "The content placed in a category header for the `category` type. The category variable is null for events with invalid (non-string) categories.",
        "ja": "The content placed in a category header for the `category` type. The category variable is null for events with invalid (non-string) categories.",
        "zh-Hans": "放置在“category”类型的类别标头中的内容。对于具有无效（非字符串）类别的事件，category变量为null。"
      }
    },
    {
      "name": "day",
      "props": {
        "outside": "boolean",
        "index": "number",
        "week": [
          {
            "date": "string",
            "time": "string",
            "year": "number",
            "month": "number",
            "day": "number",
            "hour": "number",
            "minute": "number",
            "weekday": "number",
            "hasDay": "boolean",
            "hasTime": "boolean",
            "past": "boolean",
            "present": "boolean",
            "future": "boolean"
          }
        ],
        "date": "string",
        "time": "string",
        "year": "number",
        "month": "number",
        "day": "number",
        "hour": "number",
        "minute": "number",
        "weekday": "number",
        "hasDay": "boolean",
        "hasTime": "boolean",
        "past": "boolean",
        "present": "boolean",
        "future": "boolean"
      },
      "description": {
        "en": "The content that is placed in a `week` or `month` view. The day & time object is passed through this slots scope.",
        "ja": "The content that is placed in a `week` or `month` view. The day & time object is passed through this slots scope.",
        "zh-Hans": "放置在 `week` 或 `month` 视图中的内容。day & time 对象通过此插槽作用域传递。"
      }
    },
    {
      "name": "day-body",
      "props": {
        "date": "string",
        "time": "string",
        "year": "number",
        "month": "number",
        "day": "number",
        "hour": "number",
        "minute": "number",
        "weekday": "number",
        "hasDay": "boolean",
        "hasTime": "boolean",
        "past": "boolean",
        "present": "boolean",
        "future": "boolean",
        "timeToY": "(time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false",
        "timeDelta": "(time: string | number | {hour: number, minute: number}): number | false",
        "minutesToPixels": "(minutes: number): number",
        "week": [
          {
            "date": "string",
            "time": "string",
            "year": "number",
            "month": "number",
            "day": "number",
            "hour": "number",
            "minute": "number",
            "weekday": "number",
            "hasDay": "boolean",
            "hasTime": "boolean",
            "past": "boolean",
            "present": "boolean",
            "future": "boolean"
          }
        ]
      },
      "description": {
        "en": "The content that is placed in a `day` view in the scrollable interval container. The day & time object is passed through this slots scope.",
        "ja": "The content that is placed in a `day` view in the scrollable interval container. The day & time object is passed through this slots scope.",
        "zh-Hans": "放置在可滚动间隔容器的 `day` 视图中的内容。day & time 对象通过此插槽作用域传递。"
      }
    },
    {
      "name": "day-header",
      "props": {
        "date": "string",
        "time": "string",
        "year": "number",
        "month": "number",
        "day": "number",
        "hour": "number",
        "minute": "number",
        "weekday": "number",
        "hasDay": "boolean",
        "hasTime": "boolean",
        "past": "boolean",
        "present": "boolean",
        "future": "boolean",
        "timeToY": "(time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false",
        "timeDelta": "(time: string | number | {hour: number, minute: number}): number | false",
        "minutesToPixels": "(minutes: number): number",
        "week": [
          {
            "date": "string",
            "time": "string",
            "year": "number",
            "month": "number",
            "day": "number",
            "hour": "number",
            "minute": "number",
            "weekday": "number",
            "hasDay": "boolean",
            "hasTime": "boolean",
            "past": "boolean",
            "present": "boolean",
            "future": "boolean"
          }
        ]
      },
      "description": {
        "en": "The content that is placed in a `day` view in the top container. The day & time object is passed through this slots scope.",
        "ja": "The content that is placed in a `day` view in the top container. The day & time object is passed through this slots scope.",
        "zh-Hans": "顶部容器中 `day` 视图中放置的内容。day & time 对象通过此插槽作用域传递。"
      }
    },
    {
      "name": "day-label",
      "props": {
        "date": "string",
        "time": "string",
        "year": "number",
        "month": "number",
        "day": "number",
        "hour": "number",
        "minute": "number",
        "weekday": "number",
        "hasDay": "boolean",
        "hasTime": "boolean",
        "past": "boolean",
        "present": "boolean",
        "future": "boolean"
      },
      "description": {
        "en": "The content that is placed in the day of the month space in the `custom-weekly` or `month` view. The day & time object is passed through this slots scope.",
        "ja": "The content that is placed in the day of the month space in the `custom-weekly` or `month` view. The day & time object is passed through this slots scope.",
        "zh-Hans": "放在 `custom-weekly` 或 `month` 视图中的/日空间中的内容。日期和时间对象会通过这个插槽范围。"
      }
    },
    {
      "name": "day-label-header",
      "props": {
        "date": "string",
        "time": "string",
        "year": "number",
        "month": "number",
        "day": "number",
        "hour": "number",
        "minute": "number",
        "weekday": "number",
        "hasDay": "boolean",
        "hasTime": "boolean",
        "past": "boolean",
        "present": "boolean",
        "future": "boolean"
      },
      "description": {
        "en": "The content that is placed in the day of the month space in the `week`, `day`, `4day`, or `custom-daily` view. The day & time object is passed through this slots scope.",
        "ja": "The content that is placed in the day of the month space in the `week`, `day`, `4day`, or `custom-daily` view. The day & time object is passed through this slots scope.",
        "zh-Hans": "在 `week`, `day`, `4day`, 或 `custom-daily` 视图中的月/日空间中放置的内容。日期和时间对象会通过这个插槽范围。"
      }
    },
    {
      "name": "day-month",
      "props": {
        "date": "string",
        "time": "string",
        "year": "number",
        "month": "number",
        "day": "number",
        "hour": "number",
        "minute": "number",
        "weekday": "number",
        "hasDay": "boolean",
        "hasTime": "boolean",
        "past": "boolean",
        "present": "boolean",
        "future": "boolean"
      },
      "description": {
        "en": "The content that is placed in the month space in the `week` or `month` view. The day & time object is passed through this slots scope.",
        "ja": "The content that is placed in the month space in the `week` or `month` view. The day & time object is passed through this slots scope.",
        "zh-Hans": "在 `week` 或 `month` 视图中放置在月份空间的内容。day & time 对象通过此插槽作用域传递。"
      }
    },
    {
      "name": "event",
      "props": {
        "event": "any",
        "eventParsed": {
          "input": "any",
          "start": {
            "date": "string",
            "time": "string",
            "year": "number",
            "month": "number",
            "day": "number",
            "hour": "number",
            "minute": "number",
            "weekday": "number",
            "hasDay": "boolean",
            "hasTime": "boolean",
            "past": "boolean",
            "present": "boolean",
            "future": "boolean"
          },
          "startIdentifier": "number",
          "startTimestampIdentifier": "number",
          "end": {
            "date": "string",
            "time": "string",
            "year": "number",
            "month": "number",
            "day": "number",
            "hour": "number",
            "minute": "number",
            "weekday": "number",
            "hasDay": "boolean",
            "hasTime": "boolean",
            "past": "boolean",
            "present": "boolean",
            "future": "boolean"
          },
          "endIdentifier": "number",
          "endTimestampIdentifier": "number",
          "allDay": "boolean",
          "index": "number",
          "category": "string"
        },
        "day": {
          "outside": "boolean",
          "index": "number",
          "week": [
            {
              "date": "string",
              "time": "string",
              "year": "number",
              "month": "number",
              "day": "number",
              "hour": "number",
              "minute": "number",
              "weekday": "number",
              "hasDay": "boolean",
              "hasTime": "boolean",
              "past": "boolean",
              "present": "boolean",
              "future": "boolean"
            }
          ],
          "date": "string",
          "time": "string",
          "year": "number",
          "month": "number",
          "day": "number",
          "hour": "number",
          "minute": "number",
          "weekday": "number",
          "hasDay": "boolean",
          "hasTime": "boolean",
          "past": "boolean",
          "present": "boolean",
          "future": "boolean"
        },
        "outside": "boolean",
        "start": "boolean",
        "end": "boolean",
        "timed": "boolean",
        "singleline": "boolean",
        "overlapsNoon": "boolean",
        "formatTime": "(time: VTimestamp, ampm: boolean): string",
        "timeSummary": "(): string",
        "eventSummary": "(): string"
      },
      "description": {
        "en": "The content placed in an event. This ignores the `event-name` prop.",
        "ja": "The content placed in an event. This ignores the `event-name` prop.",
        "zh-Hans": "放在事件中的内容。这就忽略了 `event-name` prop。"
      }
    },
    {
      "name": "interval",
      "props": {
        "date": "string",
        "time": "string",
        "year": "number",
        "month": "number",
        "day": "number",
        "hour": "number",
        "minute": "number",
        "weekday": "number",
        "hasDay": "boolean",
        "hasTime": "boolean",
        "past": "boolean",
        "present": "boolean",
        "future": "boolean",
        "timeToY": "(time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false",
        "timeDelta": "(time: string | number | {hour: number, minute: number}): number | false",
        "minutesToPixels": "(minutes: number): number",
        "week": [
          {
            "date": "string",
            "time": "string",
            "year": "number",
            "month": "number",
            "day": "number",
            "hour": "number",
            "minute": "number",
            "weekday": "number",
            "hasDay": "boolean",
            "hasTime": "boolean",
            "past": "boolean",
            "present": "boolean",
            "future": "boolean"
          }
        ]
      },
      "description": {
        "en": "The content that is placed in the interval space in the `day` view. The day & time object is passed through this slots scope.",
        "ja": "The content that is placed in the interval space in the `day` view. The day & time object is passed through this slots scope.",
        "zh-Hans": "在 `day` 视图中放置在间隔空间中的内容。day & time 对象通过此插槽作用域传递。"
      }
    }
  ],
  "events": [
    {
      "name": "change",
      "value": {
        "start": {
          "date": "string",
          "time": "string",
          "year": "number",
          "month": "number",
          "day": "number",
          "hour": "number",
          "minute": "number",
          "weekday": "number",
          "hasDay": "boolean",
          "hasTime": "boolean",
          "past": "boolean",
          "present": "boolean",
          "future": "boolean"
        },
        "end": {
          "date": "string",
          "time": "string",
          "year": "number",
          "month": "number",
          "day": "number",
          "hour": "number",
          "minute": "number",
          "weekday": "number",
          "hasDay": "boolean",
          "hasTime": "boolean",
          "past": "boolean",
          "present": "boolean",
          "future": "boolean"
        }
      },
      "description": {
        "en": "The range of days displayed on the calendar changed. This is triggered on initialization. The event passed is an object with start and end date objects.",
        "ja": "The range of days displayed on the calendar changed. This is triggered on initialization. The event passed is an object with start and end date objects.",
        "zh-Hans": "日历上显示的天数范围已更改。这在初始化时触发。传递的事件是一个具有开始日期和结束日期对象的对象。"
      }
    },
    {
      "name": "click:date",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, MouseEvent",
      "description": {
        "en": "The click event on the day of the month link. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "ja": "The click event on the day of the month link. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The click event on the day of the month link. The event passed is the day & time object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "click:day",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, MouseEvent",
      "description": {
        "en": "The click event on a day. The event passed is the day object. Native mouse event is passed as a second argument.",
        "ja": "The click event on a day. The event passed is the day object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The click event on a day. The event passed is the day object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "click:day-category",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, MouseEvent",
      "description": {
        "en": "The click event on a day in the `category` view. The event passed is the day object. Native mouse event is passed as a second argument.",
        "ja": "The click event on a day in the `category` view. The event passed is the day object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The click event on a day in the `category` view. The event passed is the day object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "click:event",
      "value": {
        "event": "any",
        "eventParsed": {
          "input": "any",
          "start": {
            "date": "string",
            "time": "string",
            "year": "number",
            "month": "number",
            "day": "number",
            "hour": "number",
            "minute": "number",
            "weekday": "number",
            "hasDay": "boolean",
            "hasTime": "boolean",
            "past": "boolean",
            "present": "boolean",
            "future": "boolean"
          },
          "startIdentifier": "number",
          "startTimestampIdentifier": "number",
          "end": {
            "date": "string",
            "time": "string",
            "year": "number",
            "month": "number",
            "day": "number",
            "hour": "number",
            "minute": "number",
            "weekday": "number",
            "hasDay": "boolean",
            "hasTime": "boolean",
            "past": "boolean",
            "present": "boolean",
            "future": "boolean"
          },
          "endIdentifier": "number",
          "endTimestampIdentifier": "number",
          "allDay": "boolean",
          "index": "number",
          "category": "string"
        },
        "day": {
          "outside": "boolean",
          "index": "number",
          "week": [
            {
              "date": "string",
              "time": "string",
              "year": "number",
              "month": "number",
              "day": "number",
              "hour": "number",
              "minute": "number",
              "weekday": "number",
              "hasDay": "boolean",
              "hasTime": "boolean",
              "past": "boolean",
              "present": "boolean",
              "future": "boolean"
            }
          ],
          "date": "string",
          "time": "string",
          "year": "number",
          "month": "number",
          "day": "number",
          "hour": "number",
          "minute": "number",
          "weekday": "number",
          "hasDay": "boolean",
          "hasTime": "boolean",
          "past": "boolean",
          "present": "boolean",
          "future": "boolean"
        },
        "outside": "boolean",
        "start": "boolean",
        "end": "boolean",
        "timed": "boolean",
        "singleline": "boolean",
        "overlapsNoon": "boolean",
        "formatTime": "(time: VTimestamp, ampm: boolean): string",
        "timeSummary": "(): string",
        "eventSummary": "(): string",
        "nativeEvent": "MouseEvent | TouchEvent"
      },
      "description": {
        "en": "The click event on a specific event. The event passed is the day & time object.",
        "ja": "特定のイベントのクリックイベント。渡されたイベントは日時オブジェクトです。",
        "zh-Hans": "特定事件上的单击事件。传递的事件是 day & time 对象。"
      }
    },
    {
      "name": "click:interval",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, MouseEvent",
      "description": {
        "en": "The click event at a specific interval label in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "ja": "The click event at a specific interval label in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The click event at a specific interval label in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "click:more",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, MouseEvent",
      "description": {
        "en": "The click event on the `X more` button on views with too many events in a day. Native mouse event is passed as a second argument.",
        "ja": "The click event on the `X more` button on views with too many events in a day. Native mouse event is passed as a second argument.",
        "zh-Hans": "The click event on the `X more` button on views with too many events in a day. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "click:time",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, MouseEvent",
      "description": {
        "en": "The click event at a specific time in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "ja": "The click event at a specific time in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The click event at a specific time in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "click:time-category",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, MouseEvent",
      "description": {
        "en": "The click event at a specific time in the `category` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "ja": "The click event at a specific time in the `category` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The click event at a specific time in the `category` view. The event passed is the day & time object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "contextmenu:date",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, MouseEvent",
      "description": {
        "en": "The right-click event on the day of the month link. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "ja": "The right-click event on the day of the month link. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The right-click event on the day of the month link. The event passed is the day & time object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "contextmenu:day",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, MouseEvent",
      "description": {
        "en": "The right-click event on a day. The event passed is the day object. Native mouse event is passed as a second argument.",
        "ja": "The right-click event on a day. The event passed is the day object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The right-click event on a day. The event passed is the day object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "contextmenu:day-category",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, MouseEvent",
      "description": {
        "en": "The right-click event on a day in the `category` view. The event passed is the day object. Native mouse event is passed as a second argument.",
        "ja": "The right-click event on a day in the `category` view. The event passed is the day object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The right-click event on a day in the `category` view. The event passed is the day object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "contextmenu:event",
      "value": {
        "event": "any",
        "eventParsed": {
          "input": "any",
          "start": {
            "date": "string",
            "time": "string",
            "year": "number",
            "month": "number",
            "day": "number",
            "hour": "number",
            "minute": "number",
            "weekday": "number",
            "hasDay": "boolean",
            "hasTime": "boolean",
            "past": "boolean",
            "present": "boolean",
            "future": "boolean"
          },
          "startIdentifier": "number",
          "startTimestampIdentifier": "number",
          "end": {
            "date": "string",
            "time": "string",
            "year": "number",
            "month": "number",
            "day": "number",
            "hour": "number",
            "minute": "number",
            "weekday": "number",
            "hasDay": "boolean",
            "hasTime": "boolean",
            "past": "boolean",
            "present": "boolean",
            "future": "boolean"
          },
          "endIdentifier": "number",
          "endTimestampIdentifier": "number",
          "allDay": "boolean",
          "index": "number",
          "category": "string"
        },
        "day": {
          "outside": "boolean",
          "index": "number",
          "week": [
            {
              "date": "string",
              "time": "string",
              "year": "number",
              "month": "number",
              "day": "number",
              "hour": "number",
              "minute": "number",
              "weekday": "number",
              "hasDay": "boolean",
              "hasTime": "boolean",
              "past": "boolean",
              "present": "boolean",
              "future": "boolean"
            }
          ],
          "date": "string",
          "time": "string",
          "year": "number",
          "month": "number",
          "day": "number",
          "hour": "number",
          "minute": "number",
          "weekday": "number",
          "hasDay": "boolean",
          "hasTime": "boolean",
          "past": "boolean",
          "present": "boolean",
          "future": "boolean"
        },
        "outside": "boolean",
        "start": "boolean",
        "end": "boolean",
        "timed": "boolean",
        "singleline": "boolean",
        "overlapsNoon": "boolean",
        "formatTime": "(time: VTimestamp, ampm: boolean): string",
        "timeSummary": "(): string",
        "eventSummary": "(): string",
        "nativeEvent": "MouseEvent | TouchEvent"
      },
      "description": {
        "en": "The right-click event on an event. The event passed is the day & time object.",
        "ja": "The right-click event on an event. The event passed is the day & time object.",
        "zh-Hans": "事件上的右键单击事件。 传递的事件是 day & time 对象。"
      }
    },
    {
      "name": "contextmenu:interval",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, MouseEvent",
      "description": {
        "en": "The right-click event at a specific interval label in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "ja": "The right-click event at a specific interval label in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The right-click event at a specific interval label in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "contextmenu:time",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, MouseEvent",
      "description": {
        "en": "The right-click event at a specific time in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "ja": "The right-click event at a specific time in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The right-click event at a specific time in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "contextmenu:time-category",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, MouseEvent",
      "description": {
        "en": "The right-click event at a specific time in the `category` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "ja": "The right-click event at a specific time in the `category` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The right-click event at a specific time in the `category` view. The event passed is the day & time object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "input",
      "value": {
        "date": "string",
        "time": "string",
        "year": "number",
        "month": "number",
        "day": "number",
        "hour": "number",
        "minute": "number",
        "weekday": "number",
        "hasDay": "boolean",
        "hasTime": "boolean",
        "past": "boolean",
        "present": "boolean",
        "future": "boolean"
      },
      "description": {
        "en": "An alias to the `click:date` event used to support v-model.",
        "ja": "An alias to the `click:date` event used to support v-model.",
        "zh-Hans": "用于支持 v-model 的 `click:date` 事件的别名。"
      }
    },
    {
      "name": "mousedown:day",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, MouseEvent",
      "description": {
        "en": "The mousedown event on a day. The event passed is the day object. Native mouse event is passed as a second argument.",
        "ja": "The mousedown event on a day. The event passed is the day object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The mousedown event on a day. The event passed is the day object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "mousedown:day-category",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, MouseEvent",
      "description": {
        "en": "The mousedown event on a day in the `category` view. The event passed is the day object. Native mouse event is passed as a second argument.",
        "ja": "The mousedown event on a day in the `category` view. The event passed is the day object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The mousedown event on a day in the `category` view. The event passed is the day object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "mousedown:event",
      "value": {
        "event": "any",
        "eventParsed": {
          "input": "any",
          "start": {
            "date": "string",
            "time": "string",
            "year": "number",
            "month": "number",
            "day": "number",
            "hour": "number",
            "minute": "number",
            "weekday": "number",
            "hasDay": "boolean",
            "hasTime": "boolean",
            "past": "boolean",
            "present": "boolean",
            "future": "boolean"
          },
          "startIdentifier": "number",
          "startTimestampIdentifier": "number",
          "end": {
            "date": "string",
            "time": "string",
            "year": "number",
            "month": "number",
            "day": "number",
            "hour": "number",
            "minute": "number",
            "weekday": "number",
            "hasDay": "boolean",
            "hasTime": "boolean",
            "past": "boolean",
            "present": "boolean",
            "future": "boolean"
          },
          "endIdentifier": "number",
          "endTimestampIdentifier": "number",
          "allDay": "boolean",
          "index": "number",
          "category": "string"
        },
        "day": {
          "outside": "boolean",
          "index": "number",
          "week": [
            {
              "date": "string",
              "time": "string",
              "year": "number",
              "month": "number",
              "day": "number",
              "hour": "number",
              "minute": "number",
              "weekday": "number",
              "hasDay": "boolean",
              "hasTime": "boolean",
              "past": "boolean",
              "present": "boolean",
              "future": "boolean"
            }
          ],
          "date": "string",
          "time": "string",
          "year": "number",
          "month": "number",
          "day": "number",
          "hour": "number",
          "minute": "number",
          "weekday": "number",
          "hasDay": "boolean",
          "hasTime": "boolean",
          "past": "boolean",
          "present": "boolean",
          "future": "boolean"
        },
        "outside": "boolean",
        "start": "boolean",
        "end": "boolean",
        "timed": "boolean",
        "singleline": "boolean",
        "overlapsNoon": "boolean",
        "formatTime": "(time: VTimestamp, ampm: boolean): string",
        "timeSummary": "(): string",
        "eventSummary": "(): string",
        "nativeEvent": "MouseEvent | TouchEvent"
      },
      "description": {
        "en": "The mousedown event on an event. The event passed is the day & time object.",
        "ja": "The mousedown event on an event. The event passed is the day & time object.",
        "zh-Hans": "事件上的鼠标按下事件。 传递的事件是 day & time 对象。"
      }
    },
    {
      "name": "mousedown:interval",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, MouseEvent",
      "description": {
        "en": "The mousedown event at a specific interval label in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "ja": "The mousedown event at a specific interval label in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The mousedown event at a specific interval label in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "mousedown:time",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, MouseEvent",
      "description": {
        "en": "The mousedown event at a specific time in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "ja": "The mousedown event at a specific time in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The mousedown event at a specific time in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "mousedown:time-category",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, MouseEvent",
      "description": {
        "en": "The mousedown event at a specific time in the `category` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "ja": "The mousedown event at a specific time in the `category` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The mousedown event at a specific time in the `category` view. The event passed is the day & time object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "mouseenter:day",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, MouseEvent",
      "description": {
        "en": "The mouseenter event on a day. The event passed is the day object. Native mouse event is passed as a second argument.",
        "ja": "The mouseenter event on a day. The event passed is the day object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The mouseenter event on a day. The event passed is the day object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "mouseenter:day-category",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, MouseEvent",
      "description": {
        "en": "The mouseenter event on a day in the `category` view. The event passed is the day object. Native mouse event is passed as a second argument.",
        "ja": "The mouseenter event on a day in the `category` view. The event passed is the day object. Native mouse event is passed as a second argument.",
        "zh-Hans": "在 `category` 视图中的鼠标中心事件。 通过的事件是当天对象。本机鼠标事件作为第二个参数传递。"
      }
    },
    {
      "name": "mouseenter:event",
      "value": {
        "event": "any",
        "eventParsed": {
          "input": "any",
          "start": {
            "date": "string",
            "time": "string",
            "year": "number",
            "month": "number",
            "day": "number",
            "hour": "number",
            "minute": "number",
            "weekday": "number",
            "hasDay": "boolean",
            "hasTime": "boolean",
            "past": "boolean",
            "present": "boolean",
            "future": "boolean"
          },
          "startIdentifier": "number",
          "startTimestampIdentifier": "number",
          "end": {
            "date": "string",
            "time": "string",
            "year": "number",
            "month": "number",
            "day": "number",
            "hour": "number",
            "minute": "number",
            "weekday": "number",
            "hasDay": "boolean",
            "hasTime": "boolean",
            "past": "boolean",
            "present": "boolean",
            "future": "boolean"
          },
          "endIdentifier": "number",
          "endTimestampIdentifier": "number",
          "allDay": "boolean",
          "index": "number",
          "category": "string"
        },
        "day": {
          "outside": "boolean",
          "index": "number",
          "week": [
            {
              "date": "string",
              "time": "string",
              "year": "number",
              "month": "number",
              "day": "number",
              "hour": "number",
              "minute": "number",
              "weekday": "number",
              "hasDay": "boolean",
              "hasTime": "boolean",
              "past": "boolean",
              "present": "boolean",
              "future": "boolean"
            }
          ],
          "date": "string",
          "time": "string",
          "year": "number",
          "month": "number",
          "day": "number",
          "hour": "number",
          "minute": "number",
          "weekday": "number",
          "hasDay": "boolean",
          "hasTime": "boolean",
          "past": "boolean",
          "present": "boolean",
          "future": "boolean"
        },
        "outside": "boolean",
        "start": "boolean",
        "end": "boolean",
        "timed": "boolean",
        "singleline": "boolean",
        "overlapsNoon": "boolean",
        "formatTime": "(time: VTimestamp, ampm: boolean): string",
        "timeSummary": "(): string",
        "eventSummary": "(): string",
        "nativeEvent": "MouseEvent | TouchEvent"
      },
      "description": {
        "en": "The mouseenter event on an event. The event passed is the day & time object.",
        "ja": "The mouseenter event on an event. The event passed is the day & time object.",
        "zh-Hans": "事件上的鼠标移入事件。 传递的事件是 day & time 对象。"
      }
    },
    {
      "name": "mouseenter:interval",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, MouseEvent",
      "description": {
        "en": "The mouseenter event at a specific interval label in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "ja": "The mouseenter event at a specific interval label in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The mouseenter event at a specific interval label in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "mouseenter:time",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, MouseEvent",
      "description": {
        "en": "The mouseenter event at a specific time in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "ja": "The mouseenter event at a specific time in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The mouseenter event at a specific time in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "mouseenter:time-category",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, MouseEvent",
      "description": {
        "en": "The mouseenter event at a specific time in the `category` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "ja": "The mouseenter event at a specific time in the `category` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The mouseenter event at a specific time in the `category` view. The event passed is the day & time object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "mouseleave:day",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, MouseEvent",
      "description": {
        "en": "The mouseleave event on a day. The event passed is the day object. Native mouse event is passed as a second argument.",
        "ja": "The mouseleave event on a day. The event passed is the day object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The mouseleave event on a day. The event passed is the day object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "mouseleave:day-category",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, MouseEvent",
      "description": {
        "en": "The mouseleave event on a day in the `category` view. The event passed is the day object. Native mouse event is passed as a second argument.",
        "ja": "The mouseleave event on a day in the `category` view. The event passed is the day object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The mouseleave event on a day in the `category` view. The event passed is the day object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "mouseleave:event",
      "value": {
        "event": "any",
        "eventParsed": {
          "input": "any",
          "start": {
            "date": "string",
            "time": "string",
            "year": "number",
            "month": "number",
            "day": "number",
            "hour": "number",
            "minute": "number",
            "weekday": "number",
            "hasDay": "boolean",
            "hasTime": "boolean",
            "past": "boolean",
            "present": "boolean",
            "future": "boolean"
          },
          "startIdentifier": "number",
          "startTimestampIdentifier": "number",
          "end": {
            "date": "string",
            "time": "string",
            "year": "number",
            "month": "number",
            "day": "number",
            "hour": "number",
            "minute": "number",
            "weekday": "number",
            "hasDay": "boolean",
            "hasTime": "boolean",
            "past": "boolean",
            "present": "boolean",
            "future": "boolean"
          },
          "endIdentifier": "number",
          "endTimestampIdentifier": "number",
          "allDay": "boolean",
          "index": "number",
          "category": "string"
        },
        "day": {
          "outside": "boolean",
          "index": "number",
          "week": [
            {
              "date": "string",
              "time": "string",
              "year": "number",
              "month": "number",
              "day": "number",
              "hour": "number",
              "minute": "number",
              "weekday": "number",
              "hasDay": "boolean",
              "hasTime": "boolean",
              "past": "boolean",
              "present": "boolean",
              "future": "boolean"
            }
          ],
          "date": "string",
          "time": "string",
          "year": "number",
          "month": "number",
          "day": "number",
          "hour": "number",
          "minute": "number",
          "weekday": "number",
          "hasDay": "boolean",
          "hasTime": "boolean",
          "past": "boolean",
          "present": "boolean",
          "future": "boolean"
        },
        "outside": "boolean",
        "start": "boolean",
        "end": "boolean",
        "timed": "boolean",
        "singleline": "boolean",
        "overlapsNoon": "boolean",
        "formatTime": "(time: VTimestamp, ampm: boolean): string",
        "timeSummary": "(): string",
        "eventSummary": "(): string",
        "nativeEvent": "MouseEvent | TouchEvent"
      },
      "description": {
        "en": "The mouseleave event on an event. The event passed is the day & time object.",
        "ja": "The mouseleave event on an event. The event passed is the day & time object.",
        "zh-Hans": "事件上的鼠标移出事件。 传递的事件是 day & time 对象。"
      }
    },
    {
      "name": "mouseleave:interval",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, MouseEvent",
      "description": {
        "en": "The mouseleave event at a specific interval label in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "ja": "The mouseleave event at a specific interval label in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The mouseleave event at a specific interval label in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "mouseleave:time",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, MouseEvent",
      "description": {
        "en": "The mouseleave event at a specific time in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "ja": "The mouseleave event at a specific time in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The mouseleave event at a specific time in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "mouseleave:time-category",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, MouseEvent",
      "description": {
        "en": "The mouseleave event at a specific time in the `category` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "ja": "The mouseleave event at a specific time in the `category` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The mouseleave event at a specific time in the `category` view. The event passed is the day & time object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "mousemove:day",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, MouseEvent",
      "description": {
        "en": "The mousemove event on a day. The event passed is the day object. Native mouse event is passed as a second argument.",
        "ja": "The mousemove event on a day. The event passed is the day object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The mousemove event on a day. The event passed is the day object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "mousemove:day-category",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, MouseEvent",
      "description": {
        "en": "The mousemove event on a day in the `category` view. The event passed is the day object. Native mouse event is passed as a second argument.",
        "ja": "The mousemove event on a day in the `category` view. The event passed is the day object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The mousemove event on a day in the `category` view. The event passed is the day object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "mousemove:event",
      "value": {
        "event": "any",
        "eventParsed": {
          "input": "any",
          "start": {
            "date": "string",
            "time": "string",
            "year": "number",
            "month": "number",
            "day": "number",
            "hour": "number",
            "minute": "number",
            "weekday": "number",
            "hasDay": "boolean",
            "hasTime": "boolean",
            "past": "boolean",
            "present": "boolean",
            "future": "boolean"
          },
          "startIdentifier": "number",
          "startTimestampIdentifier": "number",
          "end": {
            "date": "string",
            "time": "string",
            "year": "number",
            "month": "number",
            "day": "number",
            "hour": "number",
            "minute": "number",
            "weekday": "number",
            "hasDay": "boolean",
            "hasTime": "boolean",
            "past": "boolean",
            "present": "boolean",
            "future": "boolean"
          },
          "endIdentifier": "number",
          "endTimestampIdentifier": "number",
          "allDay": "boolean",
          "index": "number",
          "category": "string"
        },
        "day": {
          "outside": "boolean",
          "index": "number",
          "week": [
            {
              "date": "string",
              "time": "string",
              "year": "number",
              "month": "number",
              "day": "number",
              "hour": "number",
              "minute": "number",
              "weekday": "number",
              "hasDay": "boolean",
              "hasTime": "boolean",
              "past": "boolean",
              "present": "boolean",
              "future": "boolean"
            }
          ],
          "date": "string",
          "time": "string",
          "year": "number",
          "month": "number",
          "day": "number",
          "hour": "number",
          "minute": "number",
          "weekday": "number",
          "hasDay": "boolean",
          "hasTime": "boolean",
          "past": "boolean",
          "present": "boolean",
          "future": "boolean"
        },
        "outside": "boolean",
        "start": "boolean",
        "end": "boolean",
        "timed": "boolean",
        "singleline": "boolean",
        "overlapsNoon": "boolean",
        "formatTime": "(time: VTimestamp, ampm: boolean): string",
        "timeSummary": "(): string",
        "eventSummary": "(): string",
        "nativeEvent": "MouseEvent | TouchEvent"
      },
      "description": {
        "en": "The mousemove event on an event. The event passed is the day & time object.",
        "ja": "The mousemove event on an event. The event passed is the day & time object.",
        "zh-Hans": "事件上的鼠标移动事件。 传递的事件是 day & time 对象。"
      }
    },
    {
      "name": "mousemove:interval",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, MouseEvent",
      "description": {
        "en": "The mousemove event at a specific interval label in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "ja": "The mousemove event at a specific interval label in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The mousemove event at a specific interval label in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "mousemove:time",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, MouseEvent",
      "description": {
        "en": "The mousemove event at a specific time in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "ja": "The mousemove event at a specific time in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "zh-Hans": "在 `category` 视图中的鼠标中心事件。 通过的事件是当天对象。本机鼠标事件作为第二个参数传递。"
      }
    },
    {
      "name": "mousemove:time-category",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, MouseEvent",
      "description": {
        "en": "The mousemove event at a specific time in the `category` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "ja": "The mousemove event at a specific time in the `category` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The mousemove event at a specific time in the `category` view. The event passed is the day & time object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "mouseup:day",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, MouseEvent",
      "description": {
        "en": "The mouseup event on a day. The event passed is the day object. Native mouse event is passed as a second argument.",
        "ja": "The mouseup event on a day. The event passed is the day object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The mouseup event on a day. The event passed is the day object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "mouseup:day-category",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, MouseEvent",
      "description": {
        "en": "The mouseup event on a day in the `category` view. The event passed is the day object. Native mouse event is passed as a second argument.",
        "ja": "The mouseup event on a day in the `category` view. The event passed is the day object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The mouseup event on a day in the `category` view. The event passed is the day object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "mouseup:event",
      "value": {
        "event": "any",
        "eventParsed": {
          "input": "any",
          "start": {
            "date": "string",
            "time": "string",
            "year": "number",
            "month": "number",
            "day": "number",
            "hour": "number",
            "minute": "number",
            "weekday": "number",
            "hasDay": "boolean",
            "hasTime": "boolean",
            "past": "boolean",
            "present": "boolean",
            "future": "boolean"
          },
          "startIdentifier": "number",
          "startTimestampIdentifier": "number",
          "end": {
            "date": "string",
            "time": "string",
            "year": "number",
            "month": "number",
            "day": "number",
            "hour": "number",
            "minute": "number",
            "weekday": "number",
            "hasDay": "boolean",
            "hasTime": "boolean",
            "past": "boolean",
            "present": "boolean",
            "future": "boolean"
          },
          "endIdentifier": "number",
          "endTimestampIdentifier": "number",
          "allDay": "boolean",
          "index": "number",
          "category": "string"
        },
        "day": {
          "outside": "boolean",
          "index": "number",
          "week": [
            {
              "date": "string",
              "time": "string",
              "year": "number",
              "month": "number",
              "day": "number",
              "hour": "number",
              "minute": "number",
              "weekday": "number",
              "hasDay": "boolean",
              "hasTime": "boolean",
              "past": "boolean",
              "present": "boolean",
              "future": "boolean"
            }
          ],
          "date": "string",
          "time": "string",
          "year": "number",
          "month": "number",
          "day": "number",
          "hour": "number",
          "minute": "number",
          "weekday": "number",
          "hasDay": "boolean",
          "hasTime": "boolean",
          "past": "boolean",
          "present": "boolean",
          "future": "boolean"
        },
        "outside": "boolean",
        "start": "boolean",
        "end": "boolean",
        "timed": "boolean",
        "singleline": "boolean",
        "overlapsNoon": "boolean",
        "formatTime": "(time: VTimestamp, ampm: boolean): string",
        "timeSummary": "(): string",
        "eventSummary": "(): string",
        "nativeEvent": "MouseEvent | TouchEvent"
      },
      "description": {
        "en": "The mouseup event on an event. The event passed is the day & time object.",
        "ja": "The mouseup event on an event. The event passed is the day & time object.",
        "zh-Hans": "事件上的鼠标松开事件。传递的事件是 day & time 对象。"
      }
    },
    {
      "name": "mouseup:interval",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, MouseEvent",
      "description": {
        "en": "The mouseup event at a specific interval label in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "ja": "The mouseup event at a specific interval label in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The mouseup event at a specific interval label in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "mouseup:time",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, MouseEvent",
      "description": {
        "en": "The mouseup event at a specific time in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "ja": "The mouseup event at a specific time in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The mouseup event at a specific time in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "mouseup:time-category",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, MouseEvent",
      "description": {
        "en": "The mouseup event at a specific time in the `category` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "ja": "The mouseup event at a specific time in the `category` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The mouseup event at a specific time in the `category` view. The event passed is the day & time object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "moved",
      "value": {
        "date": "string",
        "time": "string",
        "year": "number",
        "month": "number",
        "day": "number",
        "hour": "number",
        "minute": "number",
        "weekday": "number",
        "hasDay": "boolean",
        "hasTime": "boolean",
        "past": "boolean",
        "present": "boolean",
        "future": "boolean"
      },
      "description": {
        "en": "One of the functions `next`, `prev`, and `move` was called. The event passed is the day object calculated for the movement.",
        "ja": "One of the functions `next`, `prev`, and `move` was called. The event passed is the day object calculated for the movement.",
        "zh-Hans": "调用了函数 `next`、`prever` 和 `move` 中的一个。传递的事件是为移动计算的 day 对象。"
      }
    },
    {
      "name": "touchend:day",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, TouchEvent",
      "description": {
        "en": "The touchend event on a day. The event passed is the day object. Native mouse event is passed as a second argument.",
        "ja": "The touchend event on a day. The event passed is the day object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The touchend event on a day. The event passed is the day object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "touchend:day-category",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, TouchEvent",
      "description": {
        "en": "The touchend event on a day in the `category` view. The event passed is the day object. Native mouse event is passed as a second argument.",
        "ja": "The touchend event on a day in the `category` view. The event passed is the day object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The touchend event on a day in the `category` view. The event passed is the day object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "touchend:event",
      "value": {
        "event": "any",
        "eventParsed": {
          "input": "any",
          "start": {
            "date": "string",
            "time": "string",
            "year": "number",
            "month": "number",
            "day": "number",
            "hour": "number",
            "minute": "number",
            "weekday": "number",
            "hasDay": "boolean",
            "hasTime": "boolean",
            "past": "boolean",
            "present": "boolean",
            "future": "boolean"
          },
          "startIdentifier": "number",
          "startTimestampIdentifier": "number",
          "end": {
            "date": "string",
            "time": "string",
            "year": "number",
            "month": "number",
            "day": "number",
            "hour": "number",
            "minute": "number",
            "weekday": "number",
            "hasDay": "boolean",
            "hasTime": "boolean",
            "past": "boolean",
            "present": "boolean",
            "future": "boolean"
          },
          "endIdentifier": "number",
          "endTimestampIdentifier": "number",
          "allDay": "boolean",
          "index": "number",
          "category": "string"
        },
        "day": {
          "outside": "boolean",
          "index": "number",
          "week": [
            {
              "date": "string",
              "time": "string",
              "year": "number",
              "month": "number",
              "day": "number",
              "hour": "number",
              "minute": "number",
              "weekday": "number",
              "hasDay": "boolean",
              "hasTime": "boolean",
              "past": "boolean",
              "present": "boolean",
              "future": "boolean"
            }
          ],
          "date": "string",
          "time": "string",
          "year": "number",
          "month": "number",
          "day": "number",
          "hour": "number",
          "minute": "number",
          "weekday": "number",
          "hasDay": "boolean",
          "hasTime": "boolean",
          "past": "boolean",
          "present": "boolean",
          "future": "boolean"
        },
        "outside": "boolean",
        "start": "boolean",
        "end": "boolean",
        "timed": "boolean",
        "singleline": "boolean",
        "overlapsNoon": "boolean",
        "formatTime": "(time: VTimestamp, ampm: boolean): string",
        "timeSummary": "(): string",
        "eventSummary": "(): string",
        "nativeEvent": "MouseEvent | TouchEvent"
      },
      "description": {
        "en": "The touchend event on am view. The event passed is the day & time object.",
        "ja": "The touchend event on am view. The event passed is the day & time object.",
        "zh-Hans": "视图上的触摸结束事件。 传递的事件是 day & time 对象。"
      }
    },
    {
      "name": "touchend:interval",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, TouchEvent",
      "description": {
        "en": "The touchend event at a specific interval label in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "ja": "The touchend event at a specific interval label in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The touchend event at a specific interval label in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "touchend:time",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, TouchEvent",
      "description": {
        "en": "The touchend event at a specific time in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "ja": "The touchend event at a specific time in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The touchend event at a specific time in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "touchend:time-category",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, TouchEvent",
      "description": {
        "en": "The touchend event at a specific time in the `category` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "ja": "The touchend event at a specific time in the `category` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The touchend event at a specific time in the `category` view. The event passed is the day & time object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "touchmove:day",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, TouchEvent",
      "description": {
        "en": "The touchmove event on a day. The event passed is the day object. Native mouse event is passed as a second argument.",
        "ja": "The touchmove event on a day. The event passed is the day object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The touchmove event on a day. The event passed is the day object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "touchmove:day-category",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, TouchEvent",
      "description": {
        "en": "The touchmove event on a day in the `category` view. The event passed is the day object. Native mouse event is passed as a second argument.",
        "ja": "The touchmove event on a day in the `category` view. The event passed is the day object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The touchmove event on a day in the `category` view. The event passed is the day object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "touchmove:event",
      "value": {
        "event": "any",
        "eventParsed": {
          "input": "any",
          "start": {
            "date": "string",
            "time": "string",
            "year": "number",
            "month": "number",
            "day": "number",
            "hour": "number",
            "minute": "number",
            "weekday": "number",
            "hasDay": "boolean",
            "hasTime": "boolean",
            "past": "boolean",
            "present": "boolean",
            "future": "boolean"
          },
          "startIdentifier": "number",
          "startTimestampIdentifier": "number",
          "end": {
            "date": "string",
            "time": "string",
            "year": "number",
            "month": "number",
            "day": "number",
            "hour": "number",
            "minute": "number",
            "weekday": "number",
            "hasDay": "boolean",
            "hasTime": "boolean",
            "past": "boolean",
            "present": "boolean",
            "future": "boolean"
          },
          "endIdentifier": "number",
          "endTimestampIdentifier": "number",
          "allDay": "boolean",
          "index": "number",
          "category": "string"
        },
        "day": {
          "outside": "boolean",
          "index": "number",
          "week": [
            {
              "date": "string",
              "time": "string",
              "year": "number",
              "month": "number",
              "day": "number",
              "hour": "number",
              "minute": "number",
              "weekday": "number",
              "hasDay": "boolean",
              "hasTime": "boolean",
              "past": "boolean",
              "present": "boolean",
              "future": "boolean"
            }
          ],
          "date": "string",
          "time": "string",
          "year": "number",
          "month": "number",
          "day": "number",
          "hour": "number",
          "minute": "number",
          "weekday": "number",
          "hasDay": "boolean",
          "hasTime": "boolean",
          "past": "boolean",
          "present": "boolean",
          "future": "boolean"
        },
        "outside": "boolean",
        "start": "boolean",
        "end": "boolean",
        "timed": "boolean",
        "singleline": "boolean",
        "overlapsNoon": "boolean",
        "formatTime": "(time: VTimestamp, ampm: boolean): string",
        "timeSummary": "(): string",
        "eventSummary": "(): string",
        "nativeEvent": "MouseEvent | TouchEvent"
      },
      "description": {
        "en": "The touchmove event on an `event` view. The event passed is the day & time object.",
        "ja": "The touchmove event on an `event` view. The event passed is the day & time object.",
        "zh-Hans": "在 `event` 视图上的触摸移动事件。 传递的事件是 day & time 对象。"
      }
    },
    {
      "name": "touchmove:interval",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, TouchEvent",
      "description": {
        "en": "The touchmove event at a specific interval label in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "ja": "The touchmove event at a specific interval label in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The touchmove event at a specific interval label in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "touchmove:time",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, TouchEvent",
      "description": {
        "en": "The touchmove event at a specific time in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "ja": "The touchmove event at a specific time in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The touchmove event at a specific time in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "touchmove:time-category",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, TouchEvent",
      "description": {
        "en": "The touchmove event at a specific time in the `category` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "ja": "The touchmove event at a specific time in the `category` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The touchmove event at a specific time in the `category` view. The event passed is the day & time object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "touchstart:day",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, TouchEvent",
      "description": {
        "en": "The touchstart event on a day. The event passed is the day object. Native mouse event is passed as a second argument.",
        "ja": "The touchstart event on a day. The event passed is the day object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The touchstart event on a day. The event passed is the day object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "touchstart:day-category",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, TouchEvent",
      "description": {
        "en": "The touchstart event on a day in the `category` view. The event passed is the day object. Native mouse event is passed as a second argument.",
        "ja": "The touchstart event on a day in the `category` view. The event passed is the day object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The touchstart event on a day in the `category` view. The event passed is the day object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "touchstart:event",
      "value": {
        "event": "any",
        "eventParsed": {
          "input": "any",
          "start": {
            "date": "string",
            "time": "string",
            "year": "number",
            "month": "number",
            "day": "number",
            "hour": "number",
            "minute": "number",
            "weekday": "number",
            "hasDay": "boolean",
            "hasTime": "boolean",
            "past": "boolean",
            "present": "boolean",
            "future": "boolean"
          },
          "startIdentifier": "number",
          "startTimestampIdentifier": "number",
          "end": {
            "date": "string",
            "time": "string",
            "year": "number",
            "month": "number",
            "day": "number",
            "hour": "number",
            "minute": "number",
            "weekday": "number",
            "hasDay": "boolean",
            "hasTime": "boolean",
            "past": "boolean",
            "present": "boolean",
            "future": "boolean"
          },
          "endIdentifier": "number",
          "endTimestampIdentifier": "number",
          "allDay": "boolean",
          "index": "number",
          "category": "string"
        },
        "day": {
          "outside": "boolean",
          "index": "number",
          "week": [
            {
              "date": "string",
              "time": "string",
              "year": "number",
              "month": "number",
              "day": "number",
              "hour": "number",
              "minute": "number",
              "weekday": "number",
              "hasDay": "boolean",
              "hasTime": "boolean",
              "past": "boolean",
              "present": "boolean",
              "future": "boolean"
            }
          ],
          "date": "string",
          "time": "string",
          "year": "number",
          "month": "number",
          "day": "number",
          "hour": "number",
          "minute": "number",
          "weekday": "number",
          "hasDay": "boolean",
          "hasTime": "boolean",
          "past": "boolean",
          "present": "boolean",
          "future": "boolean"
        },
        "outside": "boolean",
        "start": "boolean",
        "end": "boolean",
        "timed": "boolean",
        "singleline": "boolean",
        "overlapsNoon": "boolean",
        "formatTime": "(time: VTimestamp, ampm: boolean): string",
        "timeSummary": "(): string",
        "eventSummary": "(): string",
        "nativeEvent": "MouseEvent | TouchEvent"
      },
      "description": {
        "en": "The touchstart event on an event` view. The event passed is the day & time object.",
        "ja": "The touchstart event on an event` view. The event passed is the day & time object.",
        "zh-Hans": "事件视图上的触摸开始事件。 传递的事件是 day & time 对象。"
      }
    },
    {
      "name": "touchstart:interval",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, TouchEvent",
      "description": {
        "en": "The touchstart event at a specific interval label in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "ja": "The touchstart event at a specific interval label in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The touchstart event at a specific interval label in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "touchstart:time",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, TouchEvent",
      "description": {
        "en": "The touchstart event at a specific time in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "ja": "The touchstart event at a specific time in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument.",
        "zh-Hans": "The touchstart event at a specific time in the `day` view. The event passed is the day & time object. Native mouse event is passed as a second argument."
      }
    },
    {
      "name": "touchstart:time-category",
      "value": "{\n  date: string,\n  time: string,\n  year: number,\n  month: number,\n  day: number,\n  hour: number,\n  minute: number,\n  weekday: number,\n  hasDay: boolean,\n  hasTime: boolean,\n  past: boolean,\n  present: boolean,\n  future: boolean,\n  timeToY: (time: string | number | {hour: number, minute: number}, clamp: boolean = false): number | false,\n  timeDelta: (time: string | number | {hour: number, minute: number}): number | false,\n  minutesToPixels: (minutes: number): number,\n  week: [\n    {\n      date: string,\n      time: string,\n      year: number,\n      month: number,\n      day: number,\n      hour: number,\n      minute: number,\n      weekday: number,\n      hasDay: boolean,\n      hasTime: boolean,\n      past: boolean,\n      present: boolean,\n      future: boolean\n    }\n  ]\n}, TouchEvent",
      "description": {
        "en": "The touchstart event at a specific time in the `category` view. The event passed is the day & time object Native mouse event is passed as a second argument..",
        "ja": "The touchstart event at a specific time in the `category` view. The event passed is the day & time object Native mouse event is passed as a second argument..",
        "zh-Hans": "The touchstart event at a specific time in the `category` view. The event passed is the day & time object Native mouse event is passed as a second argument.."
      }
    }
  ],
  "functions": [
    {
      "name": "checkChange",
      "signature": "(): void",
      "description": {
        "en": "Checks for change in start and end dates. Updates and emits a change event if they have changed.",
        "ja": "Checks for change in start and end dates. Updates and emits a change event if they have changed.",
        "zh-Hans": "检查开始日期和结束日期的更改。 更新并发出更改事件（如果已更改）。"
      }
    },
    {
      "name": "getVisibleEvents",
      "signature": "(): CalendarEventParsed[]",
      "description": {
        "en": "Returns the list of events seen on the current calendar where each element returned has the following properties:<br>- `input`: the event passed in the `events` prop.<br>- `start`: a CalendarTimestamp of the start timestamp parsed.<br>- `startIdentifier`: a number which represents the day the event starts on.<br>- `startTimestampIdentifier`: a number which represents the day and time the event starts on.<br>- `end`: a CalendarTimestamp of the end timestamp parsed.<br>- `endIdentifier`: a number which represents the day the event ends on.<br>- `endTimestampIdentifier`: a number which represents the day & time the event ends on.<br>- `allDay`: if this is an all-day event (has no time specified in the `start`/`end` on the event).<br>- `index`: the index of the event in the given array.<br>- `category`: the category of the event if the calendar type is category, otherwise false.",
        "ja": "Returns the list of events seen on the current calendar where each element returned has the following properties:<br>- `input`: the event passed in the `events` prop.<br>- `start`: a CalendarTimestamp of the start timestamp parsed.<br>- `startIdentifier`: a number which represents the day the event starts on.<br>- `startTimestampIdentifier`: a number which represents the day and time the event starts on.<br>- `end`: a CalendarTimestamp of the end timestamp parsed.<br>- `endIdentifier`: a number which represents the day the event ends on.<br>- `endTimestampIdentifier`: a number which represents the day & time the event ends on.<br>- `allDay`: if this is an all-day event (has no time specified in the `start`/`end` on the event).<br>- `index`: the index of the event in the given array.<br>- `category`: the category of the event if the calendar type is category, otherwise false.",
        "zh-Hans": "返回当前日历上显示的事件列表，其中返回的每个元素都具有以下属性：<br>-`input`：在“events”属性中传递的事件。<br>-`start`：解析的开始时间戳的日历时间戳。<br>-`startIdentifier`：表示事件开始日期的数字。<br>-`startTimestampIdentifier`:a表示事件开始的日期和时间的数字。<br>-`end`：解析的结束时间戳的日历时间戳。<br>—`endIdentifier`：表示事件结束的日期的数字。<br>-`endTimesIdentifier`：表示事件结束的日期和时间的数字。<br>-`allDay`：是否为全天事件（没有在事件的“start`/`end`中指定时间）。<br>-`index`：事件在给定数组中的索引。<br>-`category`：如果日历类型为category，则为事件的类别，否则为false。"
      }
    },
    {
      "name": "minutesToPixels",
      "signature": "(minutes: number): number",
      "description": {
        "en": "Converts minutes to a pixel value on the y-axis for the `day` view. If the view is not `day` then -1 is returned.",
        "ja": "Converts minutes to a pixel value on the y-axis for the `day` view. If the view is not `day` then -1 is returned.",
        "zh-Hans": "将 `day` 视图的分钟数转换为 y轴 上的像素值。 如果视图不是 `day`，则返回-1。"
      }
    },
    {
      "name": "move",
      "signature": "(amount: number = 1): void",
      "description": {
        "en": "A generic function that moves the calendar next (if amount is positive) or previous (if amount is negative).",
        "ja": "A generic function that moves the calendar next (if amount is positive) or previous (if amount is negative).",
        "zh-Hans": "一个通用函数，它将日历向前移动（如果数量为正）或向前移动（如果数量为负）。"
      }
    },
    {
      "name": "next",
      "signature": "(amount: number = 1): void",
      "description": {
        "en": "Triggers the input event with a date that would progress the calendar to the next timespan. If the type is `month` it will return a day in the next month, if the type is `4day` it will return a date 4 days after `value`/`v-model`, etc.",
        "ja": "Triggers the input event with a date that would progress the calendar to the next timespan. If the type is `month` it will return a day in the next month, if the type is `4day` it will return a date 4 days after `value`/`v-model`, etc.",
        "zh-Hans": "用日期触发输入事件，该日期会使日历前进到下一个时间跨度。 如果类型为 `month`，则将在下个月返回一天；如果类型为 `4day`，则将在 `value` /`v-model` 之后 4 天返回日期，依此类推。"
      }
    },
    {
      "name": "parseEvent",
      "signature": "(input: CalendarEvent, index: number = 0): CalendarEventParsed",
      "description": {
        "en": "A utility function which takes an event and returns the parsed version of that event.",
        "ja": "A utility function which takes an event and returns the parsed version of that event.",
        "zh-Hans": "一个以事件作为参数并返回该事件的解析版本的实用工具函数。"
      }
    },
    {
      "name": "parseTimestamp",
      "signature": "(input: VTimestampInput, required?: false): CalendarTimestamp | null",
      "description": {
        "en": "A utility function which takes timestamp input and returns a timestamp object.",
        "ja": "タイムスタンプ入力を取得し、タイムスタンプオブジェクトを返すユーティリティ関数。",
        "zh-Hans": "一个以时间戳作为参数并返回该时间戳对象的实用工具函数。"
      }
    },
    {
      "name": "prev",
      "signature": "(amount: number = 1): void",
      "description": {
        "en": "Triggers the input event with a date that would progress the calendar to the previous timespan. If the type is `month` it will return a day in the previous month, if the type is `4day` it will return a date 4 days before `value`/`v-model`, etc.",
        "ja": "Triggers the input event with a date that would progress the calendar to the previous timespan. If the type is `month` it will return a day in the previous month, if the type is `4day` it will return a date 4 days before `value`/`v-model`, etc.",
        "zh-Hans": "使用日期触发输入事件，该日期会使日历前进到前一个时间跨度。 如果类型为 `month`，则将返回上个月的一天；如果类型为 `4day`，则将返回 `value`/`v-model` 之前 4 天的日期，依此类推。"
      }
    },
    {
      "name": "scrollToTime",
      "signature": "(time: number | string | { hour: number, minute: number }): boolean",
      "description": {
        "en": "Scrolls the scrollable area in the `day` view to the given time. If the time is not in a valid format or if the calendar is not in the `day` view then false is returned.",
        "ja": "`day` ビューでスクロール可能な領域を指定した時間にスクロールします。 時刻が有効な形式でない場合、またはカレンダーが `day` ビューにない場合は、false が返されます。",
        "zh-Hans": "将 `day` 视图中的可滚动区域滚动到给定时间。 如果时间格式无效，或者日历不在 `day` 视图中，则返回 false。"
      }
    },
    {
      "name": "timeDelta",
      "signature": "(time: number | string | { hour: number, minute: number }): number | false",
      "description": {
        "en": "Converts a time to a delta value for the `day` view. If the time is not in a valid format or if the calendar is not in the `day` view then false is returned. A delta value is typically between 0 and 1. If the time given is before the first interval then a negative number will be returned. If the time given is after the last interval than a number greater than 1 will be returned.",
        "ja": "Converts a time to a delta value for the `day` view. If the time is not in a valid format or if the calendar is not in the `day` view then false is returned. A delta value is typically between 0 and 1. If the time given is before the first interval then a negative number will be returned. If the time given is after the last interval than a number greater than 1 will be returned.",
        "zh-Hans": "将时间转换为“天”视图的增量值。如果时间格式无效或日历不在`天`视图中，则返回false。增量值通常介于0和1之间。如果给定的时间早于第一个间隔，则返回一个负数。如果给定的时间在最后一个间隔之后，则返回一个大于1的数字。"
      }
    },
    {
      "name": "timestampToDate",
      "signature": "(timestamp: CalendarTimestamp): Date",
      "description": {
        "en": "A utility function which takes timestamp and returns a Date.",
        "ja": "A utility function which takes timestamp and returns a Date.",
        "zh-Hans": "一个以时间戳作为参数并返回日期对象的实用工具函数。"
      }
    },
    {
      "name": "timeToY",
      "signature": "(time: number | string | { hour: number, minute: number }, clamp: boolean = true): number | false",
      "description": {
        "en": "Converts a time to a pixel value on the y-axis for the `day` view. If the time is not in a valid format or if the calendar is not in the `day` view then false is returned.",
        "ja": "Converts a time to a pixel value on the y-axis for the `day` view. If the time is not in a valid format or if the calendar is not in the `day` view then false is returned.",
        "zh-Hans": "将 `day` 视图的时间转换为 y轴 上的像素值。 如果时间格式无效，或者日历不在 `day` 视图中，则返回 false。"
      }
    },
    {
      "name": "updateTimes",
      "signature": "(): void",
      "description": {
        "en": "Updates now & today in the calendar, possibly updating the styles in the calendar.",
        "ja": "Updates now & today in the calendar, possibly updating the styles in the calendar.",
        "zh-Hans": "现在和今天在日历中更新，可能会更新日历中的样式。"
      }
    }
  ],
  "name": "v-calendar",
  "sass": [
    {
      "name": "$calendar-line-width",
      "default": "1px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$calendar-daily-head-day-label-size",
      "default": "56px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$calendar-daily-head-weekday-size",
      "default": "50px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$calendar-daily-weekday-padding",
      "default": "3px 0px 0px 0px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$calendar-daily-weekday-font-size",
      "default": "11px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$calendar-daily-day-padding",
      "default": "0px 0px 3px 0px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$calendar-daily-day-font-size",
      "default": "40px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$calendar-daily-interval-gutter-top",
      "default": "-6px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$calendar-daily-interval-gutter-width",
      "default": "4px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$calendar-daily-interval-gutter-align",
      "default": "right !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$calendar-daily-interval-gutter-line-width",
      "default": "8px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$calendar-daily-interval-gutter-font-size",
      "default": "10px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$calendar-category-column-min-width",
      "default": "200px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$calendar-weekly-weekday-padding",
      "default": "0px 4px 0px 4px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$calendar-weekly-weekday-font-size",
      "default": "11px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$calendar-weekly-day-padding",
      "default": "0px 0px 0px 0px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$calendar-weekly-day-label-size",
      "default": "32px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$calendar-weekly-day-label-font-size",
      "default": "12px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$calendar-weekly-day-label-margin",
      "default": "4px 0 0 0 !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$calendar-weekly-day-month-left",
      "default": "36px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$calendar-weekly-weeknumber-flex-basis",
      "default": "24px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$calendar-weekly-weeknumber-padding-top",
      "default": "14.5px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$calendar-event-bottom-space",
      "default": "1px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$calendar-event-border-width",
      "default": "1px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$calendar-event-border-radius",
      "default": "$border-radius-root !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$calendar-event-font-size",
      "default": "12px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$calendar-event-line-height",
      "default": "20px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$calendar-event-right-empty",
      "default": "10px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    }
  ],
  "component": true
}