module.exports = {
  "props": [
    {
      "name": "checkbox-color",
      "source": "v-data",
      "type": "string",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "custom-filter",
      "type": "function",
      "default": "gh:searchItems",
      "source": "v-data",
      "example": "(items: any[], search: string) => any[]",
      "description": {
        "en": "Function to filter items",
        "ja": "Itemをfilterするための関数",
        "zh-Hans": "过滤项目的函数"
      }
    },
    {
      "name": "custom-group",
      "type": "function",
      "default": "gh:groupItems",
      "source": "v-data",
      "example": "(items: any[], groupBy: string[], groupDesc: boolean[]) => Record<string, any[]>",
      "description": {
        "en": "Function used to group items",
        "ja": "Itemsをgroup化するために使われる関数",
        "zh-Hans": "用于分组项目的函数"
      }
    },
    {
      "name": "custom-sort",
      "type": "function",
      "default": "gh:sortItems",
      "source": "v-data",
      "example": "(items: any[], sortBy: string[], sortDesc: boolean[], locale: string, customSorters?: Record<string, compareFn>) => any[]",
      "description": {
        "en": "Function used to sort items",
        "ja": "Itemsをsortするために使われる関数",
        "zh-Hans": "用于排序项目的函数"
      }
    },
    {
      "name": "dark",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the dark theme variant to the component. You can find more information on the Material Design documentation for [dark themes](https://material.io/design/color/dark-theme.html).",
        "ja": "コンポーネントにダークテーマを適応します。詳しく知りたい場合は、Material Design documentationの<a href=\"https://material.io/design/color/dark-theme.html\">dark themes</a>を参照してください。",
        "zh-Hans": "将暗色主题变量应用到组件。你可以在 [dark themes](https://material.io/design/color/dark-theme.html) 的 Material Design 文档中找到更多有关信息。"
      }
    },
    {
      "name": "disable-filtering",
      "type": "boolean",
      "default": "false",
      "source": "v-data",
      "description": {
        "en": "Disables filtering completely",
        "ja": "Filterを完全に無効化します。",
        "zh-Hans": "完全禁用过滤"
      }
    },
    {
      "name": "disable-pagination",
      "type": "boolean",
      "default": "false",
      "source": "v-data",
      "description": {
        "en": "Disables pagination completely",
        "ja": "Paginationを完全に無効化します。",
        "zh-Hans": "完全禁用分页"
      }
    },
    {
      "name": "disable-sort",
      "type": "boolean",
      "default": "false",
      "source": "v-data",
      "description": {
        "en": "Disables sorting completely",
        "ja": "Sortingを完全に無効化します。",
        "zh-Hans": "完全禁用排序"
      }
    },
    {
      "name": "expanded",
      "type": "array",
      "default": [],
      "source": "v-data-iterator",
      "description": {
        "en": "Array of expanded items. Can be used with `.sync` modifier",
        "ja": "拡張されたitemsのarrayです。 `.sync` 修飾子と一緒に使うことができます。",
        "zh-Hans": "扩展项目数组。可以与 `.sync` 修饰符一起使用"
      }
    },
    {
      "name": "footer-props",
      "type": "object",
      "default": "undefined",
      "source": "v-data-iterator",
      "description": {
        "en": "See the [`v-data-footer`](/api/v-data-footer) API for more information",
        "ja": "詳細は [`v-data-footer`](/api/v-data-footer) API を参照してください。",
        "zh-Hans": "更多信息请参阅[`v-data-footer`](/api/v-data-foter) API"
      }
    },
    {
      "name": "group-by",
      "type": [
        "string",
        "array"
      ],
      "default": [],
      "source": "v-data",
      "description": {
        "en": "Changes which item property should be used for grouping items. Currently only supports a single grouping in the format: `group` or `['group']`. When using an array, only the first element is considered. Can be used with `.sync` modifier",
        "ja": "アイテムのグループ化に使用するアイテムプロパティを変更します。現在は、`group` または `['group']` 形式でのみサポートされています。 配列を使用する場合、最初の要素のみが考慮されます。`.sync`修飾子と一緒に使用できます。",
        "zh-Hans": "更改应用于分组项目的项目的属性. 目前只支持使用`group`或`['group']`格式更新一个单一的分组. 当我们使用数组时, 只生效第一个元素. 可以使用`.sync`修饰符."
      }
    },
    {
      "name": "group-desc",
      "type": [
        "boolean",
        "array"
      ],
      "default": [],
      "source": "v-data",
      "description": {
        "en": "Changes which direction grouping is done. Can be used with `.sync` modifier",
        "ja": "グループ化する方向を変更します。`.sync` 修飾子と一緒に使用できます。",
        "zh-Hans": "更改完成的方向分组。可以与 `.sync` 修饰器一起使用"
      }
    },
    {
      "name": "hide-default-footer",
      "type": "boolean",
      "default": "false",
      "source": "v-data-iterator",
      "description": {
        "en": "Hides default footer",
        "ja": "デフォルトのfooterを隠します。",
        "zh-Hans": "默认隐藏页脚"
      }
    },
    {
      "name": "item-key",
      "type": "string",
      "default": "'id'",
      "source": "v-data-iterator",
      "description": {
        "en": "The property on each item that is used as a unique key",
        "ja": "各itemでユニークキーとして使われるプロパティ。",
        "zh-Hans": "每个项上用作唯一键的属性"
      }
    },
    {
      "name": "items",
      "type": "array",
      "default": [],
      "source": "v-data",
      "description": {
        "en": "The array of items to display",
        "ja": "表示されるitemsのarray。",
        "zh-Hans": "要显示的项目数组"
      }
    },
    {
      "name": "items-per-page",
      "type": "number",
      "default": 10,
      "source": "v-data",
      "description": {
        "en": "Changes how many items per page should be visible. Can be used with `.sync` modifier. Setting this prop to `-1` will display all items on the page",
        "ja": "ページごとに何個のアイテムを表示するかを変更します。`.sync` 修飾子と一緒に使うことができます。このプロパティを `-1` に設定すると、ページ上のすべてのアイテムを表示します。",
        "zh-Hans": "更改每页显示多少条目。可以与 `' 一起使用。 ync` 修饰符。设置此prop 到 `-1` 将显示页面上的所有项目"
      }
    },
    {
      "name": "light",
      "type": "boolean",
      "default": "false",
      "source": "themeable",
      "description": {
        "en": "Applies the light theme variant to the component.",
        "ja": "コンポーネントにlightテーマを適用します。",
        "zh-Hans": "为组件设置浅色主题。"
      }
    },
    {
      "name": "loading",
      "type": [
        "boolean",
        "string"
      ],
      "default": "undefined",
      "source": "v-data-iterator",
      "description": {
        "en": "If `true` and no items are provided, then a loading text will be shown",
        "ja": "もし`true`にして、itemsが設定されなかった場合、ここに設定したローディングテキストが表示されます。",
        "zh-Hans": "如果为 `true` 且未提供任何项目，则将显示加载文本"
      }
    },
    {
      "name": "loading-text",
      "type": "string",
      "default": "'$vuetify.dataIterator.loadingText'",
      "source": "v-data-iterator",
      "description": {
        "en": "Text shown when `loading` is true and no items are provided",
        "ja": "`loading`がtrueで、itemsが何も設定されなかった場合に表示されるテキスト。",
        "zh-Hans": "当 `loading` 为 true 且未提供任何物品时显示的文本"
      }
    },
    {
      "name": "locale",
      "type": "string",
      "default": "'en-US'",
      "source": "v-data",
      "description": {
        "en": "Sets the locale used for sorting. This is passed into [`Intl.Collator()`](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Collator/Collator) in the default `customSort` function",
        "ja": "ソートに使用されるロケールを設定します。これはデフォルトの `customSort` 関数の [`Intl.Collator()`](https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/Intl/Collator/Collator) に渡されます。",
        "zh-Hans": "设置用于排序的语言。向默认的`customSort` 函数传递 [`Intl.Collator()`](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Collator/Collator)"
      }
    },
    {
      "name": "mobile-breakpoint",
      "type": [
        "number",
        "string"
      ],
      "default": 600,
      "source": "mobile",
      "description": {
        "en": "Used to set when to toggle between regular table and mobile view",
        "ja": "通常のテーブルとモバイル環境でのビューを切り替えるタイミングを設定するために使用",
        "zh-Hans": "用于设置何时在常规表和移动视图之间切换"
      }
    },
    {
      "name": "multi-sort",
      "type": "boolean",
      "default": "false",
      "source": "v-data",
      "description": {
        "en": "If `true` then one can sort on multiple properties",
        "ja": "`true` の場合、複数のプロパティでソートすることができます。",
        "zh-Hans": "如果为 `true`，则可以对多个属性进行排序"
      }
    },
    {
      "name": "must-sort",
      "type": "boolean",
      "default": "false",
      "source": "v-data",
      "description": {
        "en": "If `true` then one can not disable sorting, it will always switch between ascending and descending",
        "ja": "`true` の場合、ソートを無効にすることはできず、常に昇順と降順を切り替えます。",
        "zh-Hans": "如果为 `true`，则不能禁用排序，它将始终在升序和降序之间切换"
      }
    },
    {
      "name": "no-data-text",
      "type": "string",
      "default": "'$vuetify.noDataText'",
      "source": "v-data-iterator",
      "description": {
        "en": "Text shown when no items are provided to the component",
        "ja": "コンポーネントにアイテムが提供されていない場合に表示されるテキスト。",
        "zh-Hans": "未向组件提供任何项目时显示的文本"
      }
    },
    {
      "name": "no-results-text",
      "type": "string",
      "default": "'$vuetify.dataIterator.noResultsText'",
      "source": "v-data-iterator",
      "description": {
        "en": "Text shown when `search` prop is used and there are no results",
        "ja": "`search` propが使われ、結果が得られなかった場合に表示されるテキスト。",
        "zh-Hans": "使用 `search` 属性时显示的文本，但没有结果"
      }
    },
    {
      "name": "options",
      "type": "DataOptions",
      "default": {},
      "source": "v-data",
      "example": {
        "page": "number",
        "itemsPerPage": "number",
        "sortBy": "string[]",
        "sortDesc": "boolean[]",
        "groupBy": "string[]",
        "groupDesc": "boolean[]",
        "multiSort": "boolean",
        "mustSort": "boolean"
      },
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "page",
      "type": "number",
      "default": 1,
      "source": "v-data",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "search",
      "type": "string",
      "default": "undefined",
      "source": "v-data",
      "description": {
        "en": "Text input used to filter items",
        "ja": "Itemsをフィルタリングするために使用されるテキスト入力",
        "zh-Hans": "用于过滤项目的文本输入"
      }
    },
    {
      "name": "selectable-key",
      "type": "string",
      "default": "'isSelectable'",
      "source": "v-data-iterator",
      "description": {
        "en": "The property on each item that is used to determine if it is selectable or not",
        "ja": "選択可能かどうかを判断するために使用される各項目のプロパティ。",
        "zh-Hans": "每个项目上的属性，用于确定其是否可选择。"
      }
    },
    {
      "name": "server-items-length",
      "type": "number",
      "default": -1,
      "source": "v-data",
      "description": {
        "en": "Used only when data is provided by a server. Should be set to the total amount of items available on server so that pagination works correctly",
        "ja": "データがサーバから提供される場合にのみ使用されます。 ページネーションが正しく動作するように、サーバで利用可能なアイテムの合計数に設定する必要があります",
        "zh-Hans": "仅在服务器提供数据时使用。 应该设置为服务器上可用项目的总数，以便分页可以正常工作"
      }
    },
    {
      "name": "single-expand",
      "type": "boolean",
      "default": "false",
      "source": "v-data-iterator",
      "description": {
        "en": "Changes expansion mode to single expand",
        "ja": "展開モードをシングル展開に変更します",
        "zh-Hans": "将扩展模式更改为单个扩展"
      }
    },
    {
      "name": "single-select",
      "type": "boolean",
      "default": "false",
      "source": "v-data-iterator",
      "description": {
        "en": "Changes selection mode to single select",
        "ja": "選択モードを単一選択に変更します",
        "zh-Hans": "将选择模式更改为单选"
      }
    },
    {
      "name": "sort-by",
      "type": [
        "string",
        "array"
      ],
      "default": "undefined",
      "source": "v-data",
      "description": {
        "en": "Changes which item property (or properties) should be used for sort order. Can be used with `.sync` modifier",
        "ja": "どの項目プロパティ（またはプロパティ群）をソート順に使用するかを変更します。`.sync` 修飾子と一緒に使うことができます。",
        "zh-Hans": "更改应该用于排序顺序的项目属性（或属性）。可以与 `.sync` 修饰符一起使用"
      }
    },
    {
      "name": "sort-desc",
      "type": [
        "boolean",
        "array"
      ],
      "default": "undefined",
      "source": "v-data",
      "description": {
        "en": "Changes which direction sorting is done. Can be used with `.sync` modifier",
        "ja": "ソートする方向を変更します。`.sync` 修飾子と一緒に使うことができます。",
        "zh-Hans": "改变排序的方向。可以与 `.sync` 修饰器一起使用"
      }
    },
    {
      "name": "value",
      "type": "array",
      "default": [],
      "source": "v-data-iterator",
      "description": {
        "en": "Used for controlling selected rows",
        "ja": "選択された行を制御するために使用します。",
        "zh-Hans": "用于控制选定的行"
      }
    }
  ],
  "mixins": [
    "mobile",
    "themeable"
  ],
  "slots": [
    {
      "name": "default",
      "props": {
        "items": "any[]",
        "originalItemsLength": "number",
        "pagination": {
          "page": "number",
          "itemsPerPage": "number",
          "pageStart": "number",
          "pageStop": "number",
          "pageCount": "number",
          "itemsLength": "number"
        },
        "options": {
          "page": "number",
          "itemsPerPage": "number",
          "sortBy": "string[]",
          "sortDesc": "boolean[]",
          "groupBy": "string[]",
          "groupDesc": "boolean[]",
          "multiSort": "boolean",
          "mustSort": "boolean"
        },
        "groupedItems": "Array<{ name: string, items: any[] }>",
        "updateOptions": "(obj: any) => void",
        "sort": "(value: string) => void",
        "sortArray": "(sortBy: string[]) => void",
        "group": "(value: string) => void",
        "isSelected": "(item: any) => boolean",
        "select": "(item: any, value: boolean) => void",
        "isExpanded": "(item: any) => boolean",
        "expand": "(item: any, value: boolean) => void"
      },
      "source": "data-iterator",
      "description": {
        "en": "The default slot. Use this to render your items",
        "ja": "デフォルトのスロット。これを使用してアイテムをレンダリングします。",
        "zh-Hans": "默认插槽。用它渲染您的项目"
      }
    },
    {
      "name": "footer",
      "props": {
        "items": "any[]",
        "originalItemsLength": "number",
        "pagination": {
          "page": "number",
          "itemsPerPage": "number",
          "pageStart": "number",
          "pageStop": "number",
          "pageCount": "number",
          "itemsLength": "number"
        },
        "options": {
          "page": "number",
          "itemsPerPage": "number",
          "sortBy": "string[]",
          "sortDesc": "boolean[]",
          "groupBy": "string[]",
          "groupDesc": "boolean[]",
          "multiSort": "boolean",
          "mustSort": "boolean"
        },
        "groupedItems": "Array<{ name: string, items: any[] }>",
        "updateOptions": "(obj: any) => void",
        "sort": "(value: string) => void",
        "sortArray": "(sortBy: string[]) => void",
        "group": "(value: string) => void"
      },
      "source": "data-iterator",
      "description": {
        "en": "Defines a footer below the items",
        "ja": "アイテムの下にフッターを定義します",
        "zh-Hans": "在项目下方定义页脚"
      }
    },
    {
      "name": "footer.page-text",
      "props": {
        "pageStart": "number",
        "pageStop": "number",
        "itemsLength": "number"
      },
      "source": "data-iterator",
      "description": {
        "en": "This slot is forwarded to the default footer. See the [`v-data-footer`](/api/v-data-footer) API for more information",
        "ja": "このスロットはデフォルトのフッターに転送されます。詳細は[`v-data-footer`](/api/v-data-footer) API を参照してください。",
        "zh-Hans": "该插槽将转发到默认页脚。 有关更多信息，请参见 `v-data-footer` API。"
      }
    },
    {
      "name": "header",
      "props": {
        "items": "any[]",
        "originalItemsLength": "number",
        "pagination": {
          "page": "number",
          "itemsPerPage": "number",
          "pageStart": "number",
          "pageStop": "number",
          "pageCount": "number",
          "itemsLength": "number"
        },
        "options": {
          "page": "number",
          "itemsPerPage": "number",
          "sortBy": "string[]",
          "sortDesc": "boolean[]",
          "groupBy": "string[]",
          "groupDesc": "boolean[]",
          "multiSort": "boolean",
          "mustSort": "boolean"
        },
        "groupedItems": "Array<{ name: string, items: any[] }>",
        "updateOptions": "(obj: any) => void",
        "sort": "(value: string) => void",
        "sortArray": "(sortBy: string[]) => void",
        "group": "(value: string) => void"
      },
      "source": "data-iterator",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "item",
      "props": {
        "expand": "(v: boolean) => void",
        "index": "number",
        "item": "any",
        "isExpanded": "boolean",
        "isMobile": "boolean",
        "isSelected": "boolean",
        "select": "(v: boolean) => void"
      },
      "source": "data-iterator",
      "description": {
        "en": "Slot for each item",
        "ja": "各アイテムのスロット",
        "zh-Hans": "每个项目的插槽"
      }
    },
    {
      "name": "loading",
      "source": "v-data-iterator",
      "description": {
        "en": "Defines content for when `loading` is true and no items are provided",
        "ja": "`loading` が true で、itemsが提供されない場合のコンテンツを定義します。",
        "zh-Hans": "定义 `loading` 为 true 且未提供任何数据时的内容。"
      }
    },
    {
      "name": "no-data",
      "source": "v-data-iterator",
      "description": {
        "en": "Defines content for when no items are provided",
        "ja": "itemsが提供されない場合のコンテンツを定義します",
        "zh-Hans": "定义没有提供数据时的内容。"
      }
    },
    {
      "name": "no-results",
      "source": "v-data-iterator",
      "description": {
        "en": "Defines content for when `search` is provided but no results are found",
        "ja": "`search` が提供されているが、結果が見つからない場合のコンテンツを定義します。",
        "zh-Hans": "定义提供 `search` 但未找到结果时的内容。"
      }
    }
  ],
  "events": [
    {
      "name": "current-items",
      "source": "v-data",
      "value": "any[]",
      "description": {
        "en": "Emits the items provided via the **items** prop, every time the internal **computedItems** is changed.",
        "ja": "Emits the items provided via the **items** prop, every time the internal **computedItems** is changed.",
        "zh-Hans": "每当内部的**computedItems**被改变时，就会发射通过**items**道具提供的项目。"
      }
    },
    {
      "name": "input",
      "source": "v-data-iterator",
      "value": "any[]",
      "description": {
        "en": "Array of selected items",
        "ja": "選択した項目の配列",
        "zh-Hans": "选中项数组"
      }
    },
    {
      "name": "item-expanded",
      "source": "v-data-iterator",
      "value": "{ item: any, value: boolean }",
      "description": {
        "en": "Event emitted when an item is expanded or closed",
        "ja": "項目が展開または閉じられたときに発生するイベント",
        "zh-Hans": "展开或关闭项目时发出的事件"
      }
    },
    {
      "name": "item-selected",
      "source": "v-data-iterator",
      "value": "{ item: any, value: boolean }",
      "description": {
        "en": "Event emitted when an item is selected or deselected",
        "ja": "アイテムが選択または選択解除されたときに発生するイベント",
        "zh-Hans": "选中或取消选中某项时发出的事件"
      }
    },
    {
      "name": "page-count",
      "source": "v-data",
      "value": "number",
      "description": {
        "en": "Emits when the **pageCount** property of the **pagination** prop is updated",
        "ja": "Emits when the **pageCount** property of the **pagination** prop is updated",
        "zh-Hans": "当**pagination**的**pageCount**属性被更新时发出。"
      }
    },
    {
      "name": "pagination",
      "source": "v-data",
      "value": {
        "page": "number",
        "itemsPerPage": "number",
        "pageStart": "number",
        "pageStop": "number",
        "pageCount": "number",
        "itemsLength": "number"
      },
      "description": {
        "en": "Emits when something changed to the `pagination` which can be provided via the `pagination` prop",
        "ja": "Emits when something changed to the `pagination` which can be provided via the `pagination` prop",
        "zh-Hans": "当 \"pagination \"发生变化时发出，可以通过 \"pagination \"组件提供。"
      }
    },
    {
      "name": "toggle-select-all",
      "source": "v-data-iterator",
      "value": "{ items: any[], value: boolean }",
      "description": {
        "en": "Emits when the `select-all` checkbox in table header is clicked. This checkbox is enabled by the **show-select** prop",
        "ja": "Emits when the `select-all` checkbox in table header is clicked. This checkbox is enabled by the **show-select** prop",
        "zh-Hans": "Emits when the `select-all` checkbox in table header is clicked. This checkbox is enabled by the **show-select** prop"
      }
    },
    {
      "name": "update:expanded",
      "source": "v-data-iterator",
      "value": "any[]",
      "description": {
        "en": "The `.sync` event for `expanded` prop",
        "ja": "`expanded`プロパティの`.sync`イベント",
        "zh-Hans": "`expanded` 属性的 `.sync` 事件"
      }
    },
    {
      "name": "update:group-by",
      "source": "v-data",
      "value": "string | string[]",
      "description": {
        "en": "Emits when the **group-by** property of the **options** property is updated",
        "ja": "Emits when the **group-by** property of the **options** property is updated",
        "zh-Hans": "Emits when the **group-by** property of the **options** property is updated"
      }
    },
    {
      "name": "update:group-desc",
      "source": "v-data",
      "value": "boolean | boolean[]",
      "description": {
        "en": "Emits when the **group-desc** property of the **options** prop is updated",
        "ja": "Emits when the **group-desc** property of the **options** prop is updated",
        "zh-Hans": "Emits when the **group-desc** property of the **options** prop is updated"
      }
    },
    {
      "name": "update:items-per-page",
      "source": "v-data",
      "value": "number",
      "description": {
        "en": "Emits when the **items-per-page** property of the **options** prop is updated",
        "ja": "Emits when the **items-per-page** property of the **options** prop is updated",
        "zh-Hans": "Emits when the **items-per-page** property of the **options** prop is updated"
      }
    },
    {
      "name": "update:multi-sort",
      "source": "v-data",
      "value": "boolean",
      "description": {
        "en": "Emits when the **multi-sort** property of the **options** prop is updated",
        "ja": "Emits when the **multi-sort** property of the **options** prop is updated",
        "zh-Hans": "Emits when the **multi-sort** property of the **options** prop is updated"
      }
    },
    {
      "name": "update:must-sort",
      "source": "v-data",
      "value": "boolean",
      "description": {
        "en": "Emits when the **must-sort** property of the **options** prop is updated",
        "ja": "Emits when the **must-sort** property of the **options** prop is updated",
        "zh-Hans": "Emits when the **must-sort** property of the **options** prop is updated"
      }
    },
    {
      "name": "update:options",
      "source": "v-data",
      "value": {
        "page": "number",
        "itemsPerPage": "number",
        "sortBy": "string[]",
        "sortDesc": "boolean[]",
        "groupBy": "string[]",
        "groupDesc": "boolean[]",
        "multiSort": "boolean",
        "mustSort": "boolean"
      },
      "description": {
        "en": "Emits when one of the **options** properties is updated",
        "ja": "Emits when one of the **options** properties is updated",
        "zh-Hans": "Emits when one of the **options** properties is updated"
      }
    },
    {
      "name": "update:page",
      "source": "v-data",
      "value": "number",
      "description": {
        "en": "Emits when the **page** property of the **options** prop is updated",
        "ja": "Emits when the **page** property of the **options** prop is updated",
        "zh-Hans": "Emits when the **page** property of the **options** prop is updated"
      }
    },
    {
      "name": "update:sort-by",
      "source": "v-data",
      "value": "string | string[]",
      "description": {
        "en": "Emits when the **sort-by** property of the **options** prop is updated",
        "ja": "Emits when the **sort-by** property of the **options** prop is updated",
        "zh-Hans": "Emits when the **sort-by** property of the **options** prop is updated"
      }
    },
    {
      "name": "update:sort-desc",
      "source": "v-data",
      "value": "boolean | boolean[]",
      "description": {
        "en": "Emits when the **sort-desc** property of the **options** prop is updated",
        "ja": "Emits when the **sort-desc** property of the **options** prop is updated",
        "zh-Hans": "在更新 **options** 属性的 **sort-desc** 属性时发出"
      }
    }
  ],
  "functions": [],
  "name": "v-data-iterator",
  "sass": [
    {
      "name": "$data-footer-font-size",
      "default": "map-deep-get($headings, 'caption', 'size') !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$data-footer-icons-after-btn-margin-start",
      "default": "7px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$data-footer-icons-before-btn-margin-end",
      "default": "7px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$data-footer-padding",
      "default": "0 8px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$data-footer-pagination-margin-end",
      "default": "32px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$data-footer-pagination-margin-start",
      "default": "24px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$data-footer-select-margin-end",
      "default": "14px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$data-footer-select-select-margin-start",
      "default": "34px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$data-footer-select-select-margin-y",
      "default": "13px !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    },
    {
      "name": "$data-footer-select-selections-comma-font-size",
      "default": "map-deep-get($headings, 'caption', 'size') !default;",
      "description": {
        "en": "",
        "ja": "",
        "zh-Hans": ""
      }
    }
  ],
  "component": true
}